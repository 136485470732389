import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { ActivityType, ActivityValidationStateType, IActivity } from '../../../../../../interfaces/IActivity';
import { listAnimation, smoothHeight } from '../../../../UI/animations/animations';

@Component({
  selector: 'activities-list',
  templateUrl: 'activities-list.component.html',
  animations: [smoothHeight, listAnimation]
})

export class ActivitiesListComponent implements OnInit, OnChanges {

  @Input() activities: IActivity[];
  @Input() openId: string;
  @Input() showRequestValidation = false;
  @Input() editable = true;

  @Output() onDownloadFile = new EventEmitter<string>();
  @Output() onRemoveActivity = new EventEmitter<IActivity>();
  @Output() onEditActivity = new EventEmitter<IActivity>();
  @Output() onAddDocuments = new EventEmitter<IActivity>();
  @Output() onRequestValidation = new EventEmitter<IActivity>();
  @Output() onResendValidation = new EventEmitter<IActivity>();
  @Output() onModify = new EventEmitter<IActivity>();
  @Output() onRemoveDocument = new EventEmitter<{ activity: IActivity, document: { documentRef: string, fileName: string } }>();
  @Output() onUploadVersion = new EventEmitter<{ activity: IActivity, document: { documentRef: string, fileName: string } }>();
  @Output() onVersionHistoric = new EventEmitter<{ activity: IActivity, document: { documentRef: string, fileName: string } }>();
  @Output() onDetails = new EventEmitter<{ activity: IActivity, document: { documentRef: string, fileName: string } }>();

  open: { [id: string]: boolean } = {};

  ActivityType = ActivityType;

  constructor(private zone: NgZone) { }

  ngOnInit() {

  }

  isAutomaticType(activity: IActivity) {
    const auto = [ActivityType.ThesisDefence, ActivityType.AnnualEvaluation, ActivityType.AcceptanceForProcessing];
    return auto.includes(activity.activityType);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openId && changes.openId.currentValue && changes.openId.firstChange) {
      this.open[changes.openId.currentValue] = true;
    }
  }

  doOpen(activityId: string) {
    this.open[activityId] = true;
  }

  tbFn(index, item) {
    return item._id
  }

  tbFnAttached(index, item) {
    return item.documentRef
  }

  canModify(activity: IActivity) {
    return !activity.evaluated
      && activity.validationState == ActivityValidationStateType.Validated
      && !this.isAutomaticType(activity);
  }
}