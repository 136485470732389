<div class="row"
  *ngIf="student">
  <div class="col-12 col-md-6">

    <div class="card mb-4">

      <div class="card-body">

        <h5 class="card-title m-0">
          <span *ngIf="student.rd === RDType.rd99">
            {{ 'extensions' | translate }}
          </span>
          <span *ngIf="student.rd !== RDType.rd99">
            {{ 'extension' | translate }}
          </span>
        </h5>

      </div>

      <extensions-list [extensions]="student.extensions"
        [showOrder]="student.rd === RDType.rd99"></extensions-list>

      <div class="card-body"
        *ngIf="extensionProcess">
        <h5>
          {{ 'extension-request' | translate }}
          <span *ngIf="!extensionProcess.active"
            class="badge badge-danger ml-1">
            {{ 'process-inactive' | translate}}
          </span>
        </h5>
        <tasks-table [tasks]="extensionProcess.tasks"
          [process]="extensionProcess"></tasks-table>

        <ng-container *ngIf="us.isService()">
          <hr>

          <div class="mb-3">
            <div class="mb-1 small text-muted">
              {{ 'request-date' | translate }}
            </div>
            {{ extensionProcess.createdAt | moment:'DD/MM/YYYY HH:mm' }}
          </div>

          <div *ngIf="extensionProcess.requestDoc">
            <document-link [document]="extensionProcess.requestDoc"
              styleClass="mb-2"></document-link>
            <document-link [document]="doc"
              styleClass="mb-2"
              *ngFor="let doc of extensionProcess.olderRequestDocs"></document-link>
          </div>
        </ng-container>

        <end-process-button [processId]="extensionProcess._id"
          [processType]="Processes.extension"></end-process-button>
      </div>
      <div class="card-body"
        *ngIf="!extensionProcess && !loading && us.isStudent()">
        <div class="w-100 text-right">
          <button class="btn btn-outline-primary btn-block"
            [routerLink]="['request-extension']"
            [disableButton]="!extensionRequestEnabled?.enabled">
            {{ 'request-extension' | translate }}
          </button>
          <request-disabled-reasons [requestEnabled]="extensionRequestEnabled"></request-disabled-reasons>
        </div>
      </div>

    </div>

    <div class="card">
      <div class="card-body">
        <h5 class="card-title m-0">{{ 'stays' | translate }}</h5>
      </div>

      <stays-list [stays]="student.stays"></stays-list>

      <div class="card-body"
        *ngIf="stayProcesses.length > 0">
        <h5 class="card-title mb-3">{{ 'stay-requests' | translate }}</h5>
        <div *ngFor="let p of stayProcesses">
          <h6 class="card-title m-0">
            <strong>
              {{ p.university }}
            </strong>

            <span *ngIf="!p.active"
              class="badge badge-danger ml-1">
              {{ 'process-inactive' | translate}}
            </span>
          </h6>
          <tasks-table [tasks]="p.tasks"
            [process]="p"></tasks-table>
          <hr>

          <stay-process-data-view [process]="p"></stay-process-data-view>

          <div *ngIf="p.requestDoc"
            class="mb-3">
            <div class="mb-1 small text-muted">
              {{ 'request-documents' | translate }}
            </div>
            <document-link [document]="p.requestDoc"></document-link>
            <document-link [document]="doc"
              styleClass="mt-1"
              *ngFor="let doc of p.olderRequestDocs"></document-link>
          </div>

          <end-process-button [processId]="p._id"
            [processType]="Processes.stay"></end-process-button>
        </div>
      </div>

      <div class="card-body"
        *ngIf="!loading && us.isStudent()">
        <div class="w-100 text-right">
          <button class="btn btn-outline-primary btn-block"
            [routerLink]="['request-stay']"
            [disableButton]="!stayRequestEnabled?.enabled">
            {{ 'request-stay' | translate }}
          </button>
          <request-disabled-reasons [requestEnabled]="stayRequestEnabled"></request-disabled-reasons>
        </div>
      </div>
    </div>

  </div>

  <div class="col-12 col-md-6">

    <div class="card">

      <div class="card-body">

        <h5 class="card-title m-0">{{ 'leaves' | translate }}</h5>

      </div>

      <leaves-list [leaves]="student.leaves"
        [leaveDischargeProcMap]="leaveDischargeProcMap"
        [leaveRenewalProcMap]="leaveRenewalProcMap"></leaves-list>

      <div class="card-body"
        *ngIf="leaveProcess">
        <h5>
          {{ 'process.leave.name' | translate }}
          <span *ngIf="!leaveProcess.active"
            class="badge badge-danger ml-1">
            {{ 'process-inactive' | translate}}
          </span>
        </h5>
        <tasks-table [tasks]="leaveProcess.tasks"
          [process]="leaveProcess"></tasks-table>

        <ng-container *ngIf="us.isService()">
          <hr>

          <div class="mb-3">
            <div class="mb-1 small text-muted">
              {{ 'request-date' | translate }}
            </div>
            {{ leaveProcess.createdAt | moment:'DD/MM/YYYY HH:mm' }}
          </div>

          <div *ngIf="leaveProcess.requestDoc">
            <document-link [document]="leaveProcess.requestDoc"
              styleClass="mb-2"></document-link>
            <document-link [document]="doc"
              styleClass="mb-2"
              *ngFor="let doc of leaveProcess.olderRequestDocs"></document-link>
          </div>
        </ng-container>

        <end-process-button [processId]="leaveProcess._id"
          [processType]="Processes.leave"></end-process-button>
      </div>
      <div class="card-body"
        *ngIf="!leaveProcess && !loading && us.isStudent()">
        <div class="w-100 text-right">
          <button class="btn btn-outline-primary btn-block"
            [routerLink]="['request-leave']"
            [disableButton]="!leaveRequestEnabled?.enabled">
            {{ 'request-leave' | translate }}
          </button>
          <request-disabled-reasons [requestEnabled]="leaveRequestEnabled"></request-disabled-reasons>
        </div>
      </div>

    </div>

  </div>
</div>