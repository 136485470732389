<div class="card">
  <div class="card-body">

    <legend>{{ 'state' | translate }}</legend>

    <div class="mb-3">
      <student-state [state]="student.state"
        [long]="true"
        [cdsRequested]="sr.cdsRequested"></student-state>
      <student-state *ngFor="let state of student.altStates"
        [state]="state"
        [long]="false"
        [ml]="true"
        [cdsRequested]="sr.cdsRequested"></student-state>

      <div
        *ngIf="us.isService() && sh.editMode && student.state !== StudentStateType.permanentLeave && student.state !== StudentStateType.finalLeave"
        class="text-right">
        <button class="btn btn-sm btn-outline-dark"
          (click)="permLeaveModal.reset(); openPermLeaveModal = true">
          {{ 'change-to-permanent-leave' | translate }}
        </button>
      </div>
      <div *ngIf="us.isService() && student.state === StudentStateType.defended"
        class="mt-1">
        <a [routerLink]="null"
          (click)="generateThesisDefenceCert()">
          <i class="fad fa-file-word fa-inverse fa-fw mr-1"
            style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
          Certificado defensa de tesis
        </a>
      </div>
      <div *ngIf="us.isService() && sh.editMode && student.state === StudentStateType.permanentLeave && permLeave"
        class="mt-1">
        <div class="text-right">
          <button class="btn btn-sm btn-outline-dark"
            (click)="removePermLeave()">
            {{ 'remove-permanent-leave' | translate }}
          </button>
          <button class="btn btn-sm btn-outline-dark ml-2"
            (click)="finalLeaveModal.reset(); openFinalLeaveModal = true">
            {{ 'change-to-final-leave' | translate }}
          </button>
        </div>
        <div class="mt-2">
          <h6>Modelos baja definitiva</h6>
          <div>
            <a [routerLink]="null"
              (click)="generateFinalLeaveEvaluations()">
              <i class="fad fa-file-word fa-inverse fa-fw mr-1"
                style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
              Evaluaciones negativas
            </a>
          </div>
          <div>
            <a [routerLink]="null"
              (click)="generateFinalLeaveDeadline()">
              <i class="fad fa-file-word fa-inverse fa-fw mr-1"
                style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
              Fecha límite
            </a>
          </div>
          <div>
            <a [routerLink]="null"
              (click)="generateFinalLeaveEnrollment()">
              <i class="fad fa-file-word fa-inverse fa-fw mr-1"
                style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
              No matriculación
            </a>
          </div>
          <div>
            <a [routerLink]="null"
              (click)="generateFinalLeaveDecision()">
              <i class="fad fa-file-word fa-inverse fa-fw mr-1"
                style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
              Renuncia
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="us.isService() && sh.editMode && student.state === StudentStateType.finalLeave && finalLeave"
        class="mt-1">
        <div class="text-right">
          <button class="btn btn-sm btn-outline-dark"
            (click)="removeFinalLeave()">
            {{ 'remove-final-leave' | translate }}
          </button>
        </div>

      </div>
    </div>

    <legend>{{ 'dedication' | translate }}</legend>
    <text-info mb="3"
      icon="circle"
      [iconType]="student.dedication === DedicationType.fullTime ? 's' : 'r'">
      {{ student.dedication | translate }}
    </text-info>

    <legend>{{ 'thesis-deadline' | translate }}</legend>

    <text-info icon="calendar-star">
      {{ student.deadline | moment:'D [de] MMMM [de] YYYY'}}

      <a [routerLink]="null"
        *ngIf="us.isService() && student.deadlineLog"
        (click)="openDeadlineInfo = true"
        class="ml-2 text-info">
        <i class="far fa-info-circle"></i>
        más información
      </a>
    </text-info>

  </div>
</div>

<permanent-leave-modal #permLeaveModal
  [student]="student"
  [(open)]="openPermLeaveModal"></permanent-leave-modal>

<final-leave-modal #finalLeaveModal
  [student]="student"
  [(open)]="openFinalLeaveModal"></final-leave-modal>

<modal [(open)]="openDeadlineInfo"
  [title]="'thesis-deadline' | translate">
  <div class="pre text-left">
    {{ student.deadlineLog }}
  </div>
  <div footer>
    <button type="button"
      class="btn btn-outline-dark"
      (click)="openDeadlineInfo = false">
      {{ 'close' | translate }}
    </button>
  </div>
</modal>