import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { StudentRootService } from '../student-root/student-root.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { StayHttpService } from '../../../../data-services/stay-http.service';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { NgForm } from '@angular/forms';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { HelperService } from '../../../../shared/helper-service/helper.service';

@Component({
  selector: 'stay-request',
  templateUrl: './stay-request.component.html',
})
export class StayRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {

  requested = false;
  data: {
    justification: IDocumentRef;
    startDate: Date;
    endDate: Date;
    university: string;
    contactName: string;
    contactEmail: string;
  } = {
      justification: null,
      startDate: null,
      endDate: null,
      university: '',
      contactName: '',
      contactEmail: ''
    }


  doc: IDocumentRef;

  constructor(public sr: StudentRootService,
    private stay: StayHttpService,
    private ds: DocumentService,
    private ts: TranslateService,
    private cds: ConfirmationDialogService,
    private refresh: RefreshService,
    public hs: HelperService
  ) {
    super();
  }

  ngOnInit() {
  }

  manageSend(data) {
    this.stay.request(this.sr.student._id, {
      startDate: data.startDate,
      endDate: data.endDate,
      university: data.university,
      contactName: data.contactName,
      contactEmail: data.contactEmail,
      justification: data.justification
    }).subscribe((doc: IDocumentRef) => {
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
      this.requested = true;
      this.doc = doc;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    })
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

}
