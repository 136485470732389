<form #f="ngForm"
  (ngSubmit)="send()">

  <div class="form-group">
    <label for="university">
      {{ 'university-investigation-centre' | translate }}
    </label>
    <input class="form-control"
      name="university"
      id="university"
      required
      [class.is-invalid]="universityInput.invalid && f.submitted"
      #universityInput="ngModel"
      [(ngModel)]="data.university"
      [placeholder]="'university-investigation-centre' | translate">
    <validation-errors [control]="universityInput"
      [show]="f.submitted"></validation-errors>
  </div>

  <div class="form-group">
    <label for="contactName">
      {{ 'contact-person' | translate }}
    </label>
    <input class="form-control"
      name="contactName"
      id="contactName"
      required
      [class.is-invalid]="contactNameInput.invalid && f.submitted"
      #contactNameInput="ngModel"
      [(ngModel)]="data.contactName"
      [placeholder]="'contact-person' | translate">
    <validation-errors [control]="contactNameInput"
      [show]="f.submitted"></validation-errors>
  </div>

  <div class="form-group">
    <label for="contactEmail">
      {{ 'contact-person-email' | translate }}
    </label>
    <input class="form-control"
      name="contactEmail"
      id="contactEmail"
      required
      [class.is-invalid]="contactEmailInput.invalid && f.submitted"
      #contactEmailInput="ngModel"
      [pattern]="hs.EMAIL_REGEX"
      [(ngModel)]="data.contactEmail"
      [placeholder]="'contact-person-email' | translate">
    <validation-errors [control]="contactEmailInput"
      [show]="f.submitted"></validation-errors>
  </div>

  <div class="form-group">
    <label for="startDate">
      {{ 'start-date' | translate }}
    </label>

    <date-picker id="startDate"
      name="startDate"
      [placeholder]="'start-date' | translate"
      required="true"
      [isInvalid]="startDateInput.invalid && f.submitted"
      #startDateInput="ngModel"
      [time]="false"
      [(ngModel)]="data.startDate"> </date-picker>
    <validation-errors [control]="startDateInput"
      [show]="f.submitted"></validation-errors>
  </div>

  <div class="form-group">
    <label for="endDate">
      {{ 'end-date' | translate }}
    </label>

    <date-picker id="endDate"
      name="endDate"
      required="true"
      [placeholder]="'end-date' | translate"
      [isInvalid]="endDateInput.invalid && f.submitted"
      #endDateInput="ngModel"
      [time]="false"
      [(ngModel)]="data.endDate"> </date-picker>
    <validation-errors [control]="endDateInput"
      [show]="f.submitted"></validation-errors>
    <span class="invalid-feedback d-inline"
      *ngIf="data.startDate && data.endDate && !validDateRange()">
      {{ 'min-stay-duration-30-days' | translate }}
    </span>
  </div>


  <div class="form-group">
    <label>
      {{ 'justification' | translate }}
    </label>

    <validation-errors [show]="f.submitted"
      [control]="justificationInput"></validation-errors>
    <files-queue name="justification"
      required
      #justificationInput="ngModel"
      [(ngModel)]="data.justification"></files-queue>
    <small class="form-text text-muted">
      {{ 'stay-justification-note' | translate }}
    </small>
  </div>

  <div class="form-group text-right">
    <div class="d-block invalid-feedback mb-2"
      *ngIf="(f.invalid || !validDateRange()) && f.submitted">
      {{ 'check-errors' | translate }}
    </div>

    <button class="btn btn-primary"
      [disableButton]="disabled || sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'"
      type="submit">
      {{ 'send' | translate}}
    </button>
  </div>
</form>