import { Component, Input, OnInit } from '@angular/core';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { ITask } from '../../../../../../../interfaces/ITask';
import { StayHttpService } from '../../../../../data-services/stay-http.service';
import { Router } from '@angular/router';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { DocumentService } from '../../../../../shared/document-service/document.service';
import { IStayProcess } from '../../../../../../../interfaces/IStayProcess';

@Component({
  selector: 'stay-student-review',
  templateUrl: './stay-student-review.component.html',
})
export class StayStudentReviewComponent extends AbstractTaskComponent implements OnInit {

  @Input() task: ITask;

  process: IStayProcess;
  data: {
    university: string,
    contactName: string,
    contactEmail: string,
    startDate: Date,
    endDate: Date,
    justification: IDocumentRef[]
  } = {
      university: '',
      contactName: '',
      contactEmail: '',
      startDate: null,
      endDate: null,
      justification: null
    }

  constructor(private ss: StayHttpService, private router: Router, private ds: DocumentService) {
    super();
  }

  ngOnInit() {
    this.ss.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
      this.data = {
        university: process.university,
        contactName: process.contactName,
        contactEmail: process.contactEmail,
        startDate: process.startDate,
        endDate: process.endDate,
        justification: process.justification
      }
    })
  }

  manageSend(data?: any) {
    this.ss.studentReview(this.task._id, data).subscribe((docRef: IDocumentRef) => {
      this.sendEnd.next();
      setTimeout(() => {
        if (docRef) {
          this.ds.download(docRef.documentRef);
        }
      }, 1000);
      void this.router.navigate(['/app/task-completed'], { queryParams: { docRef: docRef.documentRef } });
    })
  }

}
