import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TKCDS } from '../../../../../interfaces/ICDSProcess';
import { TKDedication } from '../../../../../interfaces/IDedicationProcess';
import { TKDirection } from '../../../../../interfaces/IDirectionProcess';
import { TKDischarge } from '../../../../../interfaces/IDischargeProcess';
import { TKEvaluation } from '../../../../../interfaces/IEvaluationProcess';
import { TKExtension } from '../../../../../interfaces/IExtensionProcess';
import { TKLeave } from '../../../../../interfaces/ILeaveProcess';
import { IStudent, StudentStateType } from '../../../../../interfaces/IStudent';
import { ITask, Processes } from '../../../../../interfaces/ITask';
import { TKThesis } from '../../../../../interfaces/IThesisProcess';
import { TKValidation } from '../../../../../interfaces/IValidationProcess';
import { StudentHttpService } from '../../../data-services/student-http.service';
import { TaskHttpService } from '../../../data-services/task-http.service';
import { DocumentService } from '../../../shared/document-service/document.service';
import { HelperService } from '../../../shared/helper-service/helper.service';
import { LoadingIndicatorService } from '../../../UI/loading-indicator/loading-indicator.service';
import { UserService } from '../../../user/user.service';
import { TKPermanentLeave } from '../../../../../interfaces/IPermanentLeaveProcess';
import { TKRenewal } from '../../../../../interfaces/IRenewalProcess';
import { TKProfessor } from '../../../../../interfaces/IProfessorProcess';
import { TKStay } from '../../../../../interfaces/IStayProcess';

@Component({
  selector: 'task',
  templateUrl: 'task.component.html'
})
export class TaskComponent implements OnInit {

  Processes = Processes;
  TKValidation = TKValidation;
  TKCDS = TKCDS;
  TKDedication = TKDedication;
  TKDirection = TKDirection;
  TKExtension = TKExtension;
  TKLeave = TKLeave;
  TKPermanentLeave = TKPermanentLeave;
  TKDischarge = TKDischarge;
  TKRenewal = TKRenewal;
  TKEvaluation = TKEvaluation;
  TKThesis = TKThesis;
  TKProfessor = TKProfessor;
  TKStay = TKStay;

  task: ITask;

  notFound = false;

  student: IStudent;
  completeTaskPermission;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private ts: TaskHttpService,
    private loading: LoadingIndicatorService,
    private ds: DocumentService,
    private hs: HelperService,
    private us: UserService,
    private ss: StudentHttpService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const taskId = params.taskId;
      if (!taskId) {
        void this.router.navigate(['/']);
        return;
      }
      this.task = null;

      this.loading.start();

      this.ts.findTask(taskId).subscribe((task: ITask) => {
        if (!task) {
          this.notFound = true;
        } else {
          this.task = task;
          this.student = this.hs.getStudentFromTask(task);
          if (this.student) this.ds.studentId = this.student._id;
          this.fetchStudentPermissions();
        }

        this.loading.stop();
      });
    });
  }

  showCard() {
    return !([TKThesis.serviceValidation, TKThesis.studentReviewRequest].includes(<any>this.task.taskKey) && this.task.processKey === Processes.thesis);
  }

  fetchStudentPermissions() {
    if (this.task.assigneeId === this.us.user._id && this.student.userId === this.us.user._id) {
      this.ss.fetchMe(this.us.selectedRole.groupId).subscribe((student) => {
        if (student) {
          this.completeTaskPermission = student.permissions.completeTasks;

          // No CDS and process key is CDS
          if (student.state === StudentStateType.initial && this.task.processKey === Processes.cds) {
            this.completeTaskPermission = true;
          }

        } else {
          this.completeTaskPermission = true;
        }
      });
    } else {
      this.completeTaskPermission = true;
    }
  }
}