import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ActivityHttpService } from '../data-services/activity-http.service';
import { ActivityValidationHttpService } from '../data-services/activity-validation-http.service';
import { CDSHttpService } from '../data-services/cds-http.service';
import { DedicationHttpService } from '../data-services/dedication-http.service';
import { DirectionHttpService } from '../data-services/direction-http.service';
import { DischargeHttpService } from '../data-services/discharge-http.service';
import { EmailsHttpService } from '../data-services/emails-http.service';
import { EvaluationControlHttpService } from '../data-services/evaluation-control-http.service';
import { EvaluationHttpService } from '../data-services/evaluation-http.service';
import { ExtensionHttpService } from '../data-services/extension-http.service';
import { ImpersonatingService } from '../data-services/impersonating.service';
import { LeaveHttpService } from '../data-services/leave-http.service';
import { MessageHttpService } from '../data-services/message-http.service';
import { ProgramHttpService } from '../data-services/program-http.service';
import { RefreshService } from '../data-services/refresh.service';
import { StudentHttpService } from '../data-services/student-http.service';
import { TaskHttpService } from '../data-services/task-http.service';
import { ThesisHttpService } from '../data-services/thesis-http.service';
import { UserHttpService } from '../data-services/user-http.service';
import { SharedModule } from '../shared/shared.module';
import { AssingUserIdComponent } from '../UI/assing-user-id/assing-user-id.component';
import { DenialMsgsViewComponent } from '../UI/denial-msgs-view/denial-msgs-view.component';
import { RequestDisabledReasonsComponent } from '../UI/request-disabled-reasons/request-disabled-reasons.component';
import { SearchInputComponent } from '../UI/search-input/search-input.component';
import { ActivitiesListComponent } from './components/activities/activities-list/activities-list.component';
import { AddDocumentsComponent } from './components/activities/add-documents/add-documents.component';
import { DocumentDetailsComponent } from './components/activities/document-details/document-details.component';
import { EditActivityComponent } from './components/activities/edit-activity/edit-activity.component';
import { NewVersionComponent } from './components/activities/new-version/new-version.component';
import { VersionHistoricComponent } from './components/activities/version-historic/version-historic.component';
import { EvaluationCommitteesComponent } from './components/evaluation-committees/evaluation-committees.component';
import { EvaluationPeriodsComponent } from './components/evaluation-periods/evaluation-periods.component';
import { EvaluationsViewComponent } from './components/evaluations-view/evaluations-view.component';
import {
  CDSAcademicCommitteeValidationTaskComponent,
} from './components/process/cds-process/cds-academic-committee-validation-task/cds-academic-committee-validation-task.component';
import { CDSPgcValidationComponent } from './components/process/cds-process/cds-pgc-validation/cds-pgc-validation.component';
import {
  CDSProcessDataViewComponent,
} from './components/process/cds-process/cds-process-data-view/cds-process-data-view.component';
import { CDSRequestFormComponent } from './components/process/cds-process/cds-request-form/cds-request-form.component';
import {
  CDSServiceDirectionTeamUserCreationComponent,
} from './components/process/cds-process/cds-service-direction-team-user-creation/cds-service-direction-team-user-creation.component';
import {
  CdsServiceValidationTaskComponent,
} from './components/process/cds-process/cds-service-validation-task/cds-service-validation-task.component';
import { CDSSignComponent } from './components/process/cds-process/cds-sign/cds-sign.component';
import {
  DedicationAcValidationComponent,
} from './components/process/dedication-process/dedication-ac-validation/dedication-ac-validation.component';
import {
  DedicationDirectionTeamValidationComponent,
} from './components/process/dedication-process/dedication-direction-team-validation/dedication-direction-team-validation.component';
import {
  DedicationPgcValidationComponent,
} from './components/process/dedication-process/dedication-pgc-validation/dedication-pgc-validation.component';
import {
  DedicationProcessDataViewComponent,
} from './components/process/dedication-process/dedication-process-data-view/dedication-process-data-view.component';
import {
  DirectionAcademicCommitteeValidationTaskComponent,
} from './components/process/direction-process/direction-academic-committee-validation-task/direction-academic-commitee-validation-task.component';
import {
  DirectionPgcValidationComponent,
} from './components/process/direction-process/direction-pgc-validation/direction-pgc-validation.component';
import {
  DirectionProcessDataViewComponent,
} from './components/process/direction-process/direction-process-data-view/direction-process-data-view.component';
import {
  DirectionRequestFormComponent,
} from './components/process/direction-process/direction-request-form/direction-request-form.component';
import {
  DirectionServiceDirectionTeamUserCreationComponent,
} from './components/process/direction-process/direction-service-direction-team-user-creation/direction-service-direction-team-user-creation.component';
import {
  DirectionServiceValidationTaskComponent,
} from './components/process/direction-process/direction-service-validation-task/direction-service-validation-task.component';
import { DirectionSignComponent } from './components/process/direction-process/direction-sign/direction-sign.component';
import {
  DischargeAcValidationComponent,
} from './components/process/discharge-process/discharge-ac-validation/discharge-ac-validation.component';
import {
  DischargeServiceValidationComponent,
} from './components/process/discharge-process/discharge-service-validation/discharge-service-validation.component';
import {
  EvaluationCommitteeConfirmationComponent,
} from './components/process/evaluation/evaluation-committee-confirmation/evaluation-committee-confirmation.component';
import {
  EvaluationCommitteeEvaluationComponent,
} from './components/process/evaluation/evaluation-committee-evaluation/evaluation-committee-evaluation.component';
import {
  EvaluationDirectionTeamReportComponent,
} from './components/process/evaluation/evaluation-direction-team-report/evaluation-direction-team-report.component';
import {
  RequestEvaluationFormComponent,
} from './components/process/evaluation/request-evaluation-form/request-evaluation-form.component';
import {
  ExtensionAcValidationComponent,
} from './components/process/extension-process/extension-ac-validation/extension-ac-validation.component';
import {
  LeaveAcValidationComponent,
} from './components/process/leave-process/leave-ac-validation/leave-ac-validation.component';
import {
  LeaveServiceValidationComponent,
} from './components/process/leave-process/leave-service-validation/leave-service-validation.component';
import { TasksTableComponent } from './components/process/tasks-table/tasks-table.component';
import { ThesisAcValidation } from './components/process/thesis-process/thesis-ac-validation/thesis-ac-validation.component';
import {
  ThesisDefenceDirectionTeamValidationComponent,
} from './components/process/thesis-process/thesis-defence-direction-team-validation/thesis-defence-direction-team-validation.component';
import {
  ThesisDefencePGCValidationComponent,
} from './components/process/thesis-process/thesis-defence-pgc-validation/thesis-defence-pgc-validation.component';
import {
  ThesisDefenceRequestComponent,
} from './components/process/thesis-process/thesis-defence-request/thesis-defence-request.component';
import {
  ThesisDirectionTeamDecisionRejectReportComponent,
} from './components/process/thesis-process/thesis-direction-team-decision-reject-report/thesis-direction-team-decision-reject-report.component';
import {
  ThesisDirectionTeamValidationComponent,
} from './components/process/thesis-process/thesis-direction-team-validation/thesis-direction-team-validation.component';
import {
  ThesisDSPrincipalValidationComponent,
} from './components/process/thesis-process/thesis-ds-principal-validation/thesis-ds-principal-validation.component';
import {
  ThesisPgcsValidation,
} from './components/process/thesis-process/thesis-pgcs-validation/thesis-pgcs-validation.component';
import {
  ThesisRequestFormComponent,
} from './components/process/thesis-process/thesis-request-form/thesis-request-form.component';
import {
  ThesisServiceRegisterComponent,
} from './components/process/thesis-process/thesis-service-register/thesis-service-register.component';
import {
  ThesisServiceValidation,
} from './components/process/thesis-process/thesis-service-validation/thesis-service-validation.component';
import {
  ThesisStudentReviewRequestComponent,
} from './components/process/thesis-process/thesis-student-review-request/thesis-student-review-request.component';
import {
  ValidationReviewTaskComponent,
} from './components/process/validation-process/validation-review-task/validation-review-task.component';
import {
  ValidationValidationTaskComponent,
} from './components/process/validation-process/validation-validation-task/validation-validation-task.component';
import { EndProcessButtonComponent } from './components/service/end-process-button/end-process-button.component';
import { ProgramEditModalComponent } from './components/service/program-edit-modal/program-edit-modal.component';
import { StudentCDSComponent } from './components/students/student-cds/student-cds.component';
import {
  StudentEvaluationViewComponent,
} from './components/students/student-evaluation-view/student-evaluation-view.component';
import { AcademicInfoComponent } from './components/students/student-info/academic-info/academic-info.component';
import { EnrollmentListComponent } from './components/students/student-info/enrollments-list/enrollments-list.component';
import { EvaluationsListComponent } from './components/students/student-info/evaluations-list/evaluations-list.component';
import { ExtensionsListComponent } from './components/students/student-info/extensions-list/extensions-list.component';
import {
  ExtraExtensionsListComponent,
} from './components/students/student-info/extra-extensions-list/extra-extensions-list.component';
import { LeavesListComponent } from './components/students/student-info/leaves-list/leaves-list.component';
import { PersonalDataComponent } from './components/students/student-info/personal-data/personal-data.component';
import { StateInfoComponent } from './components/students/student-info/state-info/state-info.component';
import { StudentInfoComponent } from './components/students/student-info/student-info.component';
import {
  StudentProcessesViewComponent,
} from './components/students/student-processes-view/student-processes-view.component';
import { StudentStateComponent } from './components/students/student-state/student-state.component';
import { StudentThesisViewComponent } from './components/students/student-thesis-view/student-thesis-view.component';
import { StudentsListComponent } from './components/students/students-list/students-list.component';
import { StudentsSearchComponent } from './components/students/students-search/students-search.component';
import { StudentsSearchService } from './components/students/students-search/students-search.service';
import { AcademicCommitteeMenuComponent } from './menu/academic-committee-menu/academic-committee-menu.component';
import { CoordinatorMenuComponent } from './menu/coordinator-menu/coordinator-menu.component';
import { MenuComponent } from './menu/menu.component';
import { ServiceMenuComponent } from './menu/service-menu/service-menu.component';
import { StudentMenuComponent } from './menu/student-menu/student-menu.component';
import { SupervisorMenuComponent } from './menu/supervisor-menu/supervisor-menu.component';
import { TutorMenuComponent } from './menu/tutor-menu/tutor-menu.component';
import { MessagesDropdownComponent } from './navbar/messages-dropdown/messages-dropdown.component';
import { MessagesListComponent } from './navbar/messages-list/messages-list.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RolesDropdownComponent } from './navbar/roles-dropdown/roles-dropdown.component';
import { TasksDropdownComponent } from './navbar/tasks-dropdown/tasks-dropdown.component';
import { TasksListComponent } from './navbar/tasks-list/tasks-list.component';
import { UserDropdownComponent } from './navbar/user-dropdown/user-dropdown.component';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import {
  AcademicCommitteeStudentsComponent,
} from './routes/academic-committee/academic-committee-students/academic-committee-students.component';
import {
  CoordinatorCommitteesComponent,
} from './routes/coordinator/coordinator-committees/coordinator-committees.component';
import {
  CoordinatorEvaluationComponent,
} from './routes/coordinator/coordinator-evaluation/coordinator-evaluation.component';
import { CoordinatorPeriodsComponent } from './routes/coordinator/coordinator-periods/coordinator-periods.component';
import { CoordinatorStudentsComponent } from './routes/coordinator/coordinator-students/coordinator-students.component';
import { DownloadComponent } from './routes/download/download.component';
import { PreferencesComponent } from './routes/preferences/preferences.component';
import { CheckComponent } from './routes/service/check/check.component';
import { ExternalUsersComponent } from './routes/service/external-users/external-users.component';
import { SentEmailsComponent } from './routes/service/sent-emails/sent-emails.component';
import { ServiceEvaluationComponent } from './routes/service/service-evaluation/service-evaluation.component';
import { ServiceHomeComponent } from './routes/service/service-home/service-home.component';
import { ServiceStudentsComponent } from './routes/service/service-students/service-students.component';
import { ServiceUsersListComponent } from './routes/service/service-users-list/service-users-list.component';
import {
  StudentFileActivitiesComponent,
} from './routes/student-file/student-file-activities/student-file-activities.component';
import { StudentFileCDSViewComponent } from './routes/student-file/student-file-cds-view/student-file-cds-view.component';
import {
  StudentFileEvaluationComponent,
} from './routes/student-file/student-file-evaluation/student-file-evaluation.component';
import { StudentFileHomeComponent } from './routes/student-file/student-file-home/student-file-home.component';
import {
  StudentFileProcessesComponent,
} from './routes/student-file/student-file-processes/student-file-processes.component';
import {
  StudentFileThesisViewComponent,
} from './routes/student-file/student-file-thesis-view/student-file-thesis-view.component';
import { StudentFileComponent } from './routes/student-file/student-file.component';
import { StudentFileService } from './routes/student-file/student-file.service';
import { ActivitiesComponent } from './routes/student/activities/activities.component';
import { CDSRequestComponent } from './routes/student/cds-request/cds-request.component';
import { CDSViewComponent } from './routes/student/cds-view/cds-view.component';
import { DedicationRequestComponent } from './routes/student/dedication-request/dedication-request.component';
import { DirectionRequestComponent } from './routes/student/direction-request/direction-request.component';
import { DischargeRequestComponent } from './routes/student/discharge-request/discharge-request.component';
import { EvaluationRequestComponent } from './routes/student/evaluation-request/evaluation-request.component';
import { ExtensionRequestComponent } from './routes/student/extension-request/extension-request.component';
import { LeaveRequestComponent } from './routes/student/leave-request/leave-request.component';
import {
  ModifyEvaluationRequestComponent,
} from './routes/student/modify-evaluation-request/modify-evaluation-request.component';
import { StudentProcessesComponent } from './routes/student/procesess/student-processes.component';
import { StudentEvaluationComponent } from './routes/student/student-evaluation/student-evaluation.component';
import { StudentHomeComponent } from './routes/student/student-home/student-home.component';
import { StudentRootComponent } from './routes/student/student-root/student-root.component';
import { StudentRootService } from './routes/student/student-root/student-root.service';
import { ThesisRequestComponent } from './routes/student/thesis-request/thesis-request.component';
import { ThesisViewComponent } from './routes/student/thesis-view/thesis-view.component';
import { SupervisorStudentsComponent } from './routes/supervisor/supervisor-students/supervisor-students.component';
import { TaskCompletedComponent } from './routes/task/task-completed/task-completed.component';
import { TaskComponent } from './routes/task/task.component';
import { TutorStudentsComponent } from './routes/tutor/tutor-students/tutor-students.component';
import { MenuService } from './menu/menu.service';
import { FacultyStudentsComponent } from './routes/secretary/faculty-students/faculty-students.component';
import { SecretaryMenuComponent } from './menu/secretary-menu/secretary-menu.component';
import { SecretaryService } from './routes/secretary/secretary.service';
import { PermanentLeaveModalComponent } from './components/service/permanent-leave-modal/permanent-leave-modal.component';
import { ExtensionsCardComponent } from './components/students/student-info/extensions-card/extensions-card.component';
import { LeavesCardComponent } from './components/students/student-info/leaves-card/leaves-card.component';
import { EvaluationCommitteeStudentsComponent } from './routes/evaluation-committee/evaluation-committee-students/evaluation-committee-students.component';
import { EvaluationCommitteeMenuComponent } from './menu/evaluation-committee-menu/evaluation-committee-menu.component';
import { VideoConferenceThesisModalComponent } from './components/students/student-thesis-view/video-conference-thesis-modal/video-conference-thesis-modal.component';
import { EvaluationsCardComponent } from './components/students/student-info/evaluations-card/evaluations-card.component';
import { ExternalUsersFileComponent } from './routes/service/external-users/external-users-file/external-users-file.component';
import { ServiceProgramsComponent } from './routes/service/service-programs/service-programs.component';
import { ProgramFileComponent } from './routes/service/program-file/program-file.component';
import { ProgramFileHomeComponent } from './routes/service/program-file/program-file-home/program-file-home.component';
import { ProgramFileService } from './routes/service/program-file/program-file.service';
import { ProgramInfoComponent } from './components/programs/program-info/program-info.component';
import { ProgramFileStudentsComponent } from './routes/service/program-file/program-file-students/program-file-students.component';
import { ProgramFileProfessorsComponent } from './routes/service/program-file/program-file-professors/program-file-professors.component';
import { ProgramFileEvaluationComponent } from './routes/service/program-file/program-file-evaluation/program-file-evaluation.component';
import { ProgramFileCommitteesComponent } from './routes/service/program-file/program-file-committees/program-file-committees.component';
import { FacultyHttpService } from '../data-services/faculty-http.service';
import { FacultyFileComponent } from './routes/service/faculty-file/faculty-file.component';
import { ServiceFacultiesComponent } from './routes/service/service-faculties/service-faculties.component';
import { MainRolesComponent } from './routes/service/main-roles/main-roles.component';
import { UserFileComponent } from './routes/service/service-users-list/user-file/user-file.component';
import { QaHomeComponent } from './routes/qa/qa-home/qa-home.component';
import { QaMenuComponent } from './menu/qa-menu/qa-menu.component';
import { DisabilityCardComponent } from './components/students/student-info/disability-card/disability-card.component';
import { DisabilityListComponent } from './components/students/student-info/disability-list/disability-list.component';
import { PermanentLeaveCoordinatorReportComponent } from './components/process/permanent-leave-process/permanent-leave-coordinator-report/permanent-leave-coordinator-report.component';
import { PermanentLeaveHttpService } from '../data-services/permanent-leave-http.service';
import { CDSStudentRequestReviewComponent } from './components/process/cds-process/cds-student-request-review/cds-student-request-review.component';
import { DirectionStudentRequestReviewComponent } from './components/process/direction-process/direction-student-request-review/direction-student-request-review.component';
import { StLinkComponent } from './routes/st/st-link.component';
import { ProcessesSearchComponent } from './components/students/processes-search/processes-search.component';
import { ProcessesHttpService } from '../data-services/processes-http.service';
import { ServiceProcessesComponent } from './routes/service/service-processes/service-processes.component';
import { ThesisEditModalComponent } from './components/students/student-thesis-view/thesis-edit-modal/thesis-edit-modal.component';
import { ThesisDefenceEditModalComponent } from './components/students/student-thesis-view/thesis-defence-edit-modal/thesis-defence-edit-modal.component';
import { EvaluationProcessEditModalComponent } from './components/students/student-evaluation-view/evaluation-process-edit-modal/evaluation-process-edit-modal.component';
import { PermissionsInfoComponent } from './components/students/student-info/permissions-info/permissions-info.component';
import { AcceptGoodPracticeCodeModalComponent } from './components/accept-good-practice-code-modal/accept-good-practice-code-modal.component';
import { SendEmailModalComponent } from './components/service/send-email-modal/send-email-modal.component';
import { StudentFileEmailsComponent } from './routes/student-file/student-file-emails/student-file-emails.component';
import { TransferCardComponent } from './components/students/student-info/transfer-card/transfer-card.component';
import { ProgramProfessorsComponent } from './components/programs/program-professors/program-professors.component';
import { CoordinatorProfessorsComponent } from './routes/coordinator/coordinator-professors/coordinator-professors.component';
import { CotutelleCardComponent } from './components/students/student-info/cotutelle-card/cotutelle-card.component';
import { IndustrialMentionCardComponent } from './components/students/student-info/industrial-mention-card/industrial-mention-card.component';
import { IndustrialMentionHttpService } from '../data-services/industrial-mention-http.service';
import { ScholarshipCardComponent } from './components/students/scholarship-card/scholarship-card.component';
import { ScholarshipHttpService } from '../data-services/scholarship-http.service';
import { RenewalHttpService } from '../data-services/renewal-http.service';
import { RenewalRequestComponent } from './routes/student/renewal-request/renewal-request.component';
import { RenewalServiceValidationComponent } from './components/process/renewal-process/renewal-service-validation/renewal-service-validation.component';
import { FinalLeaveModalComponent } from './components/service/final-leave-modal/final-leave-modal.component';
import { ThesisSecretaryValidationComponent } from './components/process/thesis-process/thesis-secretary-validation/thesis-secretary-validation.component';
import { ThesisReportsModalComponent } from './components/students/student-thesis-view/thesis-reports-modal/thesis-reports-modal.component';
import { UserFileLinkComponent } from '../UI/user-file-link/user-file-link.component';
import { IndustrialMentionLaunchComponent } from './routes/student-file/industrial-mention-launch/industrial-mention-launch.component';
import { IndustrialSignComponent } from './components/process/industrial-process/industrial-sign/industrial-sign.component';
import { IndustrialProcessDataViewComponent } from './components/process/industrial-process/industrial-process-data-view/industrial-process-data-view.component';
import { CoordinatorProfessorRequestComponent } from './routes/coordinator/coordinator-professor-request/coordinator-professor-request.component';
import { ProfessorServiceValidationComponent } from './components/process/professor-process/professor-service-validation/professor-service-validation.component';
import { ProfessorProcessDataViewComponent } from './components/process/professor-process/professor-process-data-view/professor-process-data-view.component';
import { ProfessorRequestFormComponent } from './components/process/professor-process/professor-request-form/professor-request-form.component';
import { ProfessorCoordinatorReviewComponent } from './components/process/professor-process/professor-coordinator-review/professor-coordinator-review.component';
import { ProfessorPgcValidationComponent } from './components/process/professor-process/professor-pgc-validation/professor-pgc-validation.component';
import { StaysListComponent } from './components/students/student-info/stays-list/stays-list.component';
import { StayRequestComponent } from './routes/student/stay-request/stay-request.component';
import { StayHttpService } from '../data-services/stay-http.service';
import { StayDirectionTeamValidationComponent } from './components/process/stay-process/stay-direction-team-validation/stay-direction-team-validation.component';
import { StayProcessDataViewComponent } from './components/process/stay-process/stay-process-data-view/stay-process-data-view.component';
import { StayCoordinatorValidationComponent } from './components/process/stay-process/stay-coordinator-validation/stay-coordinator-validation.component';
import { StayStudentReviewComponent } from './components/process/stay-process/stay-student-review/stay-student-review.component';
import { StayRequestFormComponent } from './components/process/stay-process/stay-request-form/stay-request-form.component';

@NgModule({
  declarations: [
    RootComponent,
    MenuComponent,
    NavbarComponent,
    StudentHomeComponent,
    StudentMenuComponent,
    TutorMenuComponent,
    ServiceMenuComponent,
    CoordinatorMenuComponent,
    StudentInfoComponent,
    PersonalDataComponent,
    StateInfoComponent,
    EnrollmentListComponent,
    AcademicInfoComponent,
    ActivitiesComponent,
    ActivitiesListComponent,
    EditActivityComponent,
    AddDocumentsComponent,
    NewVersionComponent,
    VersionHistoricComponent,
    DocumentDetailsComponent,
    MessagesListComponent,
    MessagesDropdownComponent,
    TasksDropdownComponent,
    TasksListComponent,
    TutorStudentsComponent,
    StudentsListComponent,
    StudentFileComponent,
    StudentFileActivitiesComponent,
    StudentFileHomeComponent,
    StudentFileCDSViewComponent,
    StudentFileThesisViewComponent,
    StudentFileProcessesComponent,
    StudentFileEvaluationComponent,
    UserDropdownComponent,
    RolesDropdownComponent,
    TaskComponent,
    TaskCompletedComponent,
    ValidationValidationTaskComponent,
    ValidationReviewTaskComponent,
    CDSViewComponent,
    StudentCDSComponent,
    CDSRequestComponent,
    CDSRequestFormComponent,
    CDSAcademicCommitteeValidationTaskComponent,
    TasksTableComponent,
    CdsServiceValidationTaskComponent,
    DenialMsgsViewComponent,
    CDSServiceDirectionTeamUserCreationComponent,
    AssingUserIdComponent,
    ServiceHomeComponent,
    CoordinatorStudentsComponent,
    AcademicCommitteeMenuComponent,
    AcademicCommitteeStudentsComponent,
    CDSPgcValidationComponent,
    CDSProcessDataViewComponent,
    CDSSignComponent,
    SentEmailsComponent,
    DedicationRequestComponent,
    DedicationAcValidationComponent,
    DedicationDirectionTeamValidationComponent,
    DedicationPgcValidationComponent,
    DedicationProcessDataViewComponent,
    DirectionRequestComponent,
    DirectionRequestFormComponent,
    DirectionAcademicCommitteeValidationTaskComponent,
    DirectionPgcValidationComponent,
    DirectionProcessDataViewComponent,
    DirectionServiceDirectionTeamUserCreationComponent,
    DirectionServiceValidationTaskComponent,
    DirectionSignComponent,
    StudentProcessesComponent,
    StudentProcessesViewComponent,
    ExtensionsListComponent,
    ExtensionsCardComponent,
    ExtensionAcValidationComponent,
    ExtensionRequestComponent,
    LeaveRequestComponent,
    LeaveAcValidationComponent,
    LeavesListComponent,
    LeavesCardComponent,
    DischargeRequestComponent,
    DischargeAcValidationComponent,
    DischargeServiceValidationComponent,
    EvaluationsListComponent,
    StudentEvaluationComponent,
    StudentEvaluationViewComponent,
    RequestEvaluationFormComponent,
    EvaluationRequestComponent,
    EvaluationDirectionTeamReportComponent,
    EvaluationCommitteeConfirmationComponent,
    EvaluationCommitteeEvaluationComponent,
    ModifyEvaluationRequestComponent,
    ThesisViewComponent,
    StudentThesisViewComponent,
    ThesisRequestFormComponent,
    ThesisRequestComponent,
    ThesisDirectionTeamValidationComponent,
    ThesisStudentReviewRequestComponent,
    ThesisAcValidation,
    ThesisServiceValidation,
    ThesisPgcsValidation,
    StudentsSearchComponent,
    ThesisDefenceRequestComponent,
    ThesisDefenceDirectionTeamValidationComponent,
    ThesisDefencePGCValidationComponent,
    ThesisServiceRegisterComponent,
    ThesisDSPrincipalValidationComponent,
    DownloadComponent,
    ServiceStudentsComponent,
    ServiceUsersListComponent,
    StudentRootComponent,
    ExtraExtensionsListComponent,
    SupervisorStudentsComponent,
    SupervisorMenuComponent,
    ExternalUsersComponent,
    StudentStateComponent,
    SearchInputComponent,
    CoordinatorEvaluationComponent,
    CoordinatorProfessorsComponent,
    EvaluationsViewComponent,
    ServiceEvaluationComponent,
    CoordinatorCommitteesComponent,
    EvaluationCommitteesComponent,
    CoordinatorPeriodsComponent,
    EvaluationPeriodsComponent,
    ThesisDirectionTeamDecisionRejectReportComponent,
    LeaveServiceValidationComponent,
    RequestDisabledReasonsComponent,
    ProgramEditModalComponent,
    CheckComponent,
    PreferencesComponent,
    EndProcessButtonComponent,
    FacultyStudentsComponent,
    SecretaryMenuComponent,
    PermanentLeaveModalComponent,
    EvaluationCommitteeStudentsComponent,
    EvaluationCommitteeMenuComponent,
    VideoConferenceThesisModalComponent,
    EvaluationsCardComponent,
    ExternalUsersFileComponent,
    ServiceProgramsComponent,
    ProgramFileComponent,
    ProgramFileHomeComponent,
    ProgramInfoComponent,
    ProgramProfessorsComponent,
    ProgramFileStudentsComponent,
    ProgramFileProfessorsComponent,
    ProgramFileEvaluationComponent,
    ProgramFileCommitteesComponent,
    FacultyFileComponent,
    ServiceFacultiesComponent,
    MainRolesComponent,
    UserFileComponent,
    QaHomeComponent,
    QaMenuComponent,
    DisabilityCardComponent,
    DisabilityListComponent,
    PermanentLeaveCoordinatorReportComponent,
    CDSStudentRequestReviewComponent,
    DirectionStudentRequestReviewComponent,
    StLinkComponent,
    ProcessesSearchComponent,
    ServiceProcessesComponent,
    ThesisEditModalComponent,
    ThesisDefenceEditModalComponent,
    EvaluationProcessEditModalComponent,
    PermissionsInfoComponent,
    AcceptGoodPracticeCodeModalComponent,
    SendEmailModalComponent,
    StudentFileEmailsComponent,
    TransferCardComponent,
    CotutelleCardComponent,
    IndustrialMentionCardComponent,
    ScholarshipCardComponent,
    RenewalRequestComponent,
    RenewalServiceValidationComponent,
    FinalLeaveModalComponent,
    ThesisSecretaryValidationComponent,
    ThesisReportsModalComponent,
    UserFileLinkComponent,
    IndustrialMentionLaunchComponent,
    IndustrialSignComponent,
    IndustrialProcessDataViewComponent,
    CoordinatorProfessorRequestComponent,
    ProfessorServiceValidationComponent,
    ProfessorProcessDataViewComponent,
    ProfessorRequestFormComponent,
    ProfessorCoordinatorReviewComponent,
    ProfessorPgcValidationComponent,
    StaysListComponent,
    StayRequestComponent,
    StayDirectionTeamValidationComponent,
    StayProcessDataViewComponent,
    StayCoordinatorValidationComponent,
    StayStudentReviewComponent,
    StayRequestFormComponent
  ],
  imports: [
    SharedModule,
    RootRoutingModule,
    TranslateModule
  ],
  exports: [

  ],
  providers: [
    StudentHttpService,
    MessageHttpService,
    ActivityHttpService,
    CDSHttpService,
    DirectionHttpService,
    DedicationHttpService,
    UserHttpService,
    ActivityValidationHttpService,
    ProgramHttpService,
    RefreshService,
    StudentFileService,
    EmailsHttpService,
    ExtensionHttpService,
    LeaveHttpService,
    PermanentLeaveHttpService,
    DischargeHttpService,
    RenewalHttpService,
    EvaluationHttpService,
    ThesisHttpService,
    TaskHttpService,
    ImpersonatingService,
    StudentRootService,
    EvaluationControlHttpService,
    StudentsSearchService,
    MenuService,
    SecretaryService,
    ProgramFileService,
    FacultyHttpService,
    ProcessesHttpService,
    IndustrialMentionHttpService,
    ScholarshipHttpService,
    StayHttpService
  ],
})
export class RootModule { }
