import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IActiveProcesses, IStudent } from '../../../../../../../interfaces/IStudent';
import { NgForm } from '@angular/forms';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { UserService } from '../../../../../user/user.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { IPermissions, PermissionsType } from '../../../../../../../interfaces/IPermissions';
import { StudentHttpService } from '../../../../../data-services/student-http.service';
import { DADUtils, IStateProccesses } from '../../../../../../../utils/DADUtils';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { EvaluationControlHttpService } from '../../../../../data-services/evaluation-control-http.service';

@Component({
  selector: 'permissions-info',
  templateUrl: './permissions-info.component.html',
})
export class PermissionsInfoComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() student: IStudent;

  customPermissions: {
    endDate: Date,
    permissions: Partial<IPermissions>
  } = {
      endDate: null,
      permissions: {}
    };

  open = false;

  studentPermissions: IPermissions;

  PermissionsType = PermissionsType;
  Object = Object;
  DADUtils = DADUtils;
  permissionsTypeArray = Object.keys(PermissionsType).map(k => PermissionsType[k]);

  procStates: IStateProccesses;
  activeProcesses: IActiveProcesses;

  constructor(public sh: ServiceHttpService, public us: UserService,
    private rs: RefreshService,
    private alerts: PopAlertsService,
    private ecs: EvaluationControlHttpService,
    private ss: StudentHttpService) { }

  ngOnInit() {
    this.ss.fetchActiveProcesses(this.student._id).subscribe((activeProcesses) => {
      this.activeProcesses = activeProcesses;

      let directionAllowed = false;
      this.ecs.directionChangeAllowed(this.student._id).subscribe((res) => {
        directionAllowed = res.allowed;
        this.procStates = DADUtils.processRequestEnabled(this.student, this.activeProcesses, directionAllowed);
        this.setStudentPermissions();
      })

    })
  }

  setStudentPermissions() {
    this.studentPermissions = _.cloneDeep(this.student.permissions);
    this.studentPermissions[PermissionsType.requestCDS] = this.studentPermissions[PermissionsType.requestCDS] && this.procStates.cds.enabled && this.activeProcesses.cds <= 0;
    this.studentPermissions[PermissionsType.requestDirection] = this.studentPermissions[PermissionsType.requestDirection] && this.procStates.direction.enabled && this.activeProcesses.direction <= 0;
    this.studentPermissions[PermissionsType.requestDedication] = this.studentPermissions[PermissionsType.requestDedication] && this.procStates.dedication.enabled && this.activeProcesses.dedication <= 0;
    this.studentPermissions[PermissionsType.requestExtension] = this.studentPermissions[PermissionsType.requestExtension] && this.procStates.extension.enabled && this.activeProcesses.extension <= 0;
    this.studentPermissions[PermissionsType.requestTemporalLeave] = this.studentPermissions[PermissionsType.requestTemporalLeave] && this.procStates.leave.enabled && this.activeProcesses.leave <= 0;
    this.studentPermissions[PermissionsType.requestMedicalLeave] = this.studentPermissions[PermissionsType.requestMedicalLeave] && this.procStates.leave.enabled && this.activeProcesses.leave <= 0;
    this.studentPermissions[PermissionsType.requestEvaluation] = this.studentPermissions[PermissionsType.requestEvaluation] && this.procStates.evaluation.enabled && this.activeProcesses.evaluation <= 0;
    this.studentPermissions[PermissionsType.requestThesis] = this.studentPermissions[PermissionsType.requestThesis] && this.procStates.thesis.enabled && this.activeProcesses.thesis <= 0 && DADUtils.countProcesses(this.activeProcesses) <= 0;
  }

  doOpen() {
    this.customPermissions = {
      endDate: moment().endOf('d').add(15, 'd').toDate(),
      permissions: _.cloneDeep(this.studentPermissions)
    };
    if (this.hasCustomPermissions()) {
      for (const perm of this.permissionsTypeArray) {
        if (this.student.customPermissions.permissions[perm] === true) {
          this.customPermissions.permissions[perm] = true;
        }
      }
    }
    this.open = true;
  }

  alreadyInStudentsPermissions(perm: PermissionsType) {
    return this.studentPermissions[perm] === true;
  }

  alreadyActive(perm: PermissionsType) {
    switch (perm) {
      case PermissionsType.requestCDS:
        return this.activeProcesses.cds > 0;
      case PermissionsType.requestDirection:
        return this.activeProcesses.direction > 0;
      case PermissionsType.requestDedication:
        return this.activeProcesses.dedication > 0;
      case PermissionsType.requestExtension:
        return this.activeProcesses.extension > 0;
      case PermissionsType.requestTemporalLeave:
        return this.activeProcesses.leave > 0;
      case PermissionsType.requestMedicalLeave:
        return this.activeProcesses.leave > 0;
      case PermissionsType.requestEvaluation:
        return this.activeProcesses.evaluation > 0;
      case PermissionsType.requestThesis:
        return this.activeProcesses.thesis > 0;
      default:
        return false;
    }
  }

  hasCustomPermissions() {
    return this.student.customPermissions && moment().endOf('d').isBefore(this.student.customPermissions.endDate);
  }

  save() {
    const permissions = this.permissionsTypeArray.filter(pt => this.customPermissions.permissions[pt] === true && !this.alreadyInStudentsPermissions(pt));

    this.sh.setCustomPermissions(this.student._id, this.customPermissions.endDate, permissions).subscribe(() => {
      this.rs.onStudentChange.next();
      this.open = false;
      this.alerts.saveSuccess();
    });
  }

  remove() {
    this.sh.removeCustomPermissions(this.student._id).subscribe(() => {
      this.student.customPermissions = null;
      this.open = false;
      this.alerts.pop({
        styleClass: 'info',
        msg: 'Eliminado correctamente'
      })
    });
  }

}
