import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IActiveProcesses, IStudent } from '../../../../../../interfaces/IStudent';
import { Processes } from '../../../../../../interfaces/ITask';
import { DADUtils, IStateProccesses } from '../../../../../../utils/DADUtils';
import { StudentHttpService } from '../../../../data-services/student-http.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { UserService } from '../../../../user/user.service';

import * as moment from 'moment-timezone';
import { EvaluationControlHttpService } from '../../../../data-services/evaluation-control-http.service';

@Injectable()
export class StudentRootService {

  activeProcesses: IActiveProcesses = {
    [Processes.cds]: 0,
    [Processes.validation]: 0,
    [Processes.evaluation]: 0,
    [Processes.thesis]: 0,
    [Processes.defence]: 0,
    [Processes.dedication]: 0,
    [Processes.direction]: 0,
    [Processes.industrial]: 0,
    [Processes.extension]: 0,
    [Processes.leave]: 0,
    [Processes.permanentLeave]: 0,
    [Processes.discharge]: 0,
    [Processes.renewal]: 0,
    [Processes.professor]: 0,
    [Processes.stay]: 0,
  }

  student: IStudent = null;
  readyObs = new Subject();
  ready = false;

  cdsRequested;

  procStates: IStateProccesses;

  constructor(private ds: DocumentService, private us: UserService, private ss: StudentHttpService, private ecs: EvaluationControlHttpService) { }

  fetch() {
    if (!this.us.selectedRole.groupId) return;
    this.ss.fetchMe(this.us.selectedRole.groupId).subscribe((student) => {
      student.user = this.us.user;
      this.student = student;
      this.ds.studentId = student._id;
      if (student) {
        this.ss.fetchActiveProcesses(student._id).subscribe((activeProcesses) => {
          this.activeProcesses = activeProcesses;
          let directionAllowed = true;
          this.ecs.directionChangeAllowed(student._id).subscribe((res) => {
            directionAllowed = res.allowed;
            this.procStates = DADUtils.processRequestEnabled(this.student, this.activeProcesses, directionAllowed);
            this.ready = true;
            this.readyObs.next();
          });
        })
      } else {
        this.ready = true;
        this.readyObs.next();
      }
    });
  }


}