import { StudentStateType } from './IStudent';

export enum PermissionsType {
  manageActivities = "manageActivities",
  completeTasks = "completeTasks",
  requestCDS = "requestCDS",
  requestDirection = "requestDirection",
  requestDedication = "requestDedication",
  requestExtension = "requestExtension",
  requestTemporalLeave = "requestTemporalLeave",
  requestMedicalLeave = "requestMedicalLeave",
  requestEvaluation = "requestEvaluation",
  requestThesis = "requestThesis",
  requestStay = "requestStay"
}

export type IPermissions = {
  [key in PermissionsType]: boolean
}

export type IPermissionsState = {
  [key in StudentStateType]: IPermissions
}



