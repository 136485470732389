import { IDocumentRef } from "./IDocument";
import { IProcess } from "./IProcess";
import { IProgram } from "./IProgram";
import { IStudent } from "./IStudent";

export interface IIndustrialProcess extends IProcess {
  state: IndustrialPState,
  studentId: string,
  programId: string,
  industrial: boolean,
  agreementDocs?: IDocumentRef[],
  agreementDate?: Date,
  organization?: string,
  organizationContact?: string

  // Populate
  student?: IStudent,
  program?: IProgram
}

export enum IndustrialPState {
  PendingSigns = "PendingSigns"
}

export enum TKIndustrial {
  studentSign = "studentSign",
  postgraduateCommitteePresidentSign = "postgraduateCommitteePresidentSign",
  directionTeamSign = "directionTeamSign",
  academicCommitteeSign = "academicCommitteeSign",
  doctoralSchoolPrincipalSign = "doctoralSchoolPrincipalSign",
}