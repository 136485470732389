import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActivityType, IActivity } from '../../../../../../interfaces/IActivity';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';

@Component({
  selector: 'activity-view',
  templateUrl: 'activity-view.component.html'
})
export class ActivityViewComponent implements OnInit {
  @Input() activity: IActivity

  @Input() showEdit = false;
  @Input() clickableType = false;

  @Input() full = true;

  @Output() onTypeClick = new EventEmitter<any>();

  ActivityType = ActivityType;

  constructor(public hs: HelperService, public ds: DocumentService) { }

  ngOnInit() { }
}