import * as moment from 'moment-timezone';

import { IUserCDSInfo } from '../interfaces/ICDSProcess';
import { PermissionsType } from '../interfaces/IPermissions';
import { IProgram } from '../interfaces/IProgram';
import {
  EvaluationPeriodType,
  EvaluationType,
  IActiveProcesses,
  IDisability,
  IEvaluation,
  IStudent,
  RDType,
  StudentStateType,
} from '../interfaces/IStudent';
import { Processes } from '../interfaces/ITask';
import { IThesisCommitteeMemberData } from '../interfaces/IThesisProcess';
import { Group, IUser, Role } from '../interfaces/IUser';
import { IExternalUser } from '../interfaces/IExternalUser';

export class DADUtils {

  static inEvaluationPeriod(start, end): boolean {
    if (start && end) {
      let firstEvaluation = moment(start).month("March").date(1);
      let firstEvaluation2 = moment(end).month("March").date(1);
      let secondEvaluation = moment(start).month("September").date(1);
      let secondEvaluation2 = moment(end).month("September").date(1);

      return firstEvaluation.isBetween(start, end) ||
        firstEvaluation2.isBetween(start, end) ||
        secondEvaluation.isBetween(start, end) ||
        secondEvaluation2.isBetween(start, end);
    }

    return false;
  }

  // 2022/2023 (1/10/22 - 30/9/23) => 2022
  static getAcademicYear(date: Date): number {
    let res = moment(date).year();
    if (moment(date).month() <= 8) {
      res = res - 1;
    }
    return res;
  }

  // 20222023
  static getFullAcademicYear(date: Date): string {
    let academicYear = DADUtils.getAcademicYear(date);
    return academicYear + '' + (academicYear + 1)
  }

  static getFullAcademicYearShort(date: Date): string {
    let academicYear = DADUtils.getAcademicYear(date);
    let full = academicYear + '' + (academicYear + 1);
    return full.substring(2, 4) + full.substring(6);
  }

  // 20222023
  static getFullEvaluationYear(date: Date): string {
    const evaluationYear = moment(date).year();
    return (evaluationYear - 1) + '' + (evaluationYear)
  }

  static getEvaluationPeriod(date: Date): EvaluationPeriodType {
    return moment(date).month() >= 8 ? EvaluationPeriodType.september : EvaluationPeriodType.march;
  }

  // 20222023 -> 2022/2023
  static formatAcademicYear(year: string): string {
    if (!year || year.length !== 8) {
      return ""
    }

    return `${year.substring(0, 4)}/${year.substring(4)}`;
  }

  // 20222023 -> 2023
  static getEvaluationYear(year: string): number {
    if (!year || year.length !== 8) {
      return null
    }
    return +year.substring(4);
  }

  static lastEvaluation(date: Date = new Date()): { year: string, period: EvaluationPeriodType, date: Date } {
    let evDate;
    if (moment(date).month() >= 2 && moment(date).month() < 8) {
      // between march and september ->  march this year
      evDate = moment(date).date(1).month(2).startOf('day');
    } else if (moment(date).month() >= 0 && moment(date).month() < 2) {
      // january and february -> september last year
      evDate = moment(date).date(1).month(8).subtract(1, 'year').startOf('day');
    } else {
      // september to december -> september this year
      evDate = moment(date).date(1).month(8).startOf('day');
    }

    return {
      year: DADUtils.getFullEvaluationYear(evDate),
      period: DADUtils.getEvaluationPeriod(evDate),
      date: evDate
    }
  }

  // zero based month
  static getMonth(period: EvaluationPeriodType): number {
    return period === EvaluationPeriodType.march ? 2 : 8;
  }

  static isInEvaluation(program: IProgram, date = new Date()): boolean {
    const year = moment(date).year();
    const p1start = moment(date).month(2).date(1);
    const p1End = moment(`${year}${program.evaluationPeriod1End}`, "YYYYMMDD");
    const p2start = moment(date).month(8).date(1);
    const p2End = moment(`${year}${program.evaluationPeriod2End}`, "YYYYMMDD");
    return moment(date).isBetween(p1start, p1End, 'day', '[]')
      || moment(date).isBetween(p2start, p2End, 'day', '[]');
  }

  static isInEvaluationPeriod1(program: IProgram, date = new Date()): boolean {
    const year = moment(date).year();
    const p1start = moment(date).month(2).date(1);
    const p1End = moment(`${year}${program.evaluationPeriod1End}`, "YYYYMMDD");
    return moment(date).isBetween(p1start, p1End, 'day', '[]');
  }

  static isInEvaluationPeriod2(program: IProgram, date = new Date()): boolean {
    const year = moment(date).year();
    const p2start = moment(date).month(8).date(1);
    const p2End = moment(`${year}${program.evaluationPeriod2End}`, "YYYYMMDD");
    return moment(date).isBetween(p2start, p2End, 'day', '[]');
  }

  static evaluationStartDate(evaluation: IEvaluation): Date {
    return moment()
      .year(DADUtils.getEvaluationYear(evaluation.year))
      .month(evaluation.period === EvaluationPeriodType.march ? 2 : 8)
      .date(1).startOf('d').toDate();
  }

  static evaluationEndDate(evaluation: IEvaluation): Date {
    return moment()
      .year(DADUtils.getEvaluationYear(evaluation.year))
      .month(evaluation.period === EvaluationPeriodType.march ? 2 : 8)
      .add(1, 'month').endOf('month').endOf('day').toDate();
  }

  static evaluationPeriodEndToDate(period: string): Date {
    const month = +period.substring(0, 2);
    const dateOfMonth = +period.substring(2);
    return moment().month(month - 1).date(dateOfMonth).toDate();
  }

  static evaluationDeadline(program: IProgram, period: EvaluationPeriodType): Date {
    const endStr = period === EvaluationPeriodType.march ? program.evaluationPeriod1End : program.evaluationPeriod2End;
    return DADUtils.evaluationPeriodEndToDate(endStr);
  }

  static parseRole(role: string): { role: Role, group: Group, groupId?: string, groupName?: string } {
    const parts = role.split('-');

    return {
      role: Role[parts[0]],
      group: Group[parts[1]],
      groupId: parts[2] ? parts[2] : undefined
    }
  }

  static fullname(value: IUser | IStudent | IUserCDSInfo | IThesisCommitteeMemberData | IExternalUser) {
    if (!value) return "";
    return `${value.lastName}, ${value.firstName}`
  }

  static fullnameAlt(value: IUser | IStudent | IUserCDSInfo | IThesisCommitteeMemberData) {
    if (!value) return "";
    return `${value.firstName} ${value.lastName}`
  }

  static add16DaysForDefence(baseDate: Date): moment.Moment {
    let date = moment(baseDate);

    // August?
    if (date.month() === 7) {
      date = moment([date.year(), 8, 1]);
    }

    date.add(16, 'days');

    // august
    if (date.month() === 7) {
      date = moment([date.year(), 8, 1]).add(date.date(), 'days');
    }

    return date;
  }

  static firstEnrollmentDate(student: IStudent): Date {
    if (student.transfer && student.transferFirstEnrollment) {
      return student.transferFirstEnrollment;
    }
    let res = null;
    if (!student.enrollments) return res;
    for (const enrollment of student.enrollments) {
      if (!res || moment(res).isAfter(enrollment.date)) {
        res = enrollment.date
      }
    }

    return res;
  }

  static processRequestEnabled(student: IStudent, activeProcesses: IActiveProcesses, directionAllowed?: boolean): IStateProccesses {
    let customPermissionsValid = student.customPermissions && moment(student.customPermissions.endDate).endOf('d').isAfter(moment());
    if (customPermissionsValid !== true) customPermissionsValid = false;
    /**
     * THESIS: 
     * No other active process 
     * && at least 6 months since the last direction change
     * && at least 1 positive evaluation in the last year
     * && deadline is OK
     * && permission is OK
     *  */
    const thesisReasons = [];
    const dirChanged6M = student.cds?.changes.find(c => c.directionChanged && moment().diff(c.date, 'months', true) <= 6);
    if (dirChanged6M) {
      thesisReasons.push("direction-changed-in-the-last-6-months");
    }
    const positiveEvaluation = student.evaluations
      .find(e => e.evaluation === EvaluationType.positive && moment().diff(DADUtils.evaluationEndDate(e), 'years', true) <= 1);
    if (!positiveEvaluation) {
      thesisReasons.push("no-positive-evaluation-in-the-last-year")
    }
    const deadlineOk = student.deadline && moment(student.deadline).isSameOrAfter(moment(), 'd');
    if (!deadlineOk) {
      thesisReasons.push("deadline-is-over")
    }
    DADUtils.activeProcessesMsgs(activeProcesses).forEach(r => thesisReasons.push(r));
    if (!student.permissions[PermissionsType.requestThesis]) {
      thesisReasons.push(student.state + "_INFO");
    }

    const moreThanOneYear = moment().diff(DADUtils.firstEnrollmentDate(student), 'years', true) >= 1;
    if (!moreThanOneYear) {
      thesisReasons.push("less-than-one-year-enrolled");
    }

    const thesisEnabled = DADUtils.countProcesses(activeProcesses) <= 0
      && (
        thesisReasons.length <= 0
        || (customPermissionsValid && student.customPermissions.permissions.requestThesis));

    /**
     * CDS No active processes && permission of the status is Ok
     */
    const cdsReasons = [];
    DADUtils.activeProcessesMsgs(activeProcesses).forEach(r => cdsReasons.push(r));
    if (!student.permissions[PermissionsType.requestCDS]) {
      cdsReasons.push(student.state + "_INFO");
    }
    const cdsEnabled = DADUtils.countProcesses(activeProcesses) <= 0
      && (
        cdsReasons.length <= 0
        || (customPermissionsValid && student.customPermissions.permissions.requestCDS)
      );

    /**
     * DEDICATION No active processes && permission of the status is Ok
     */
    const dedicationNoCompat = [
      Processes.cds,
      Processes.dedication,
      Processes.direction,
      Processes.industrial,
      Processes.evaluation,
      Processes.thesis,
      Processes.permanentLeave
    ]
    const dedicationReasons = [];
    DADUtils.activeProcessesMsgs(activeProcesses, dedicationNoCompat).forEach(r => dedicationReasons.push(r));
    if (!student.permissions[PermissionsType.requestDedication]) {
      dedicationReasons.push(student.state + "_INFO");
    }

    const maxDedicationChanges = 2;
    const dedicationChanges = student.cds?.changes.filter(c => c.dedicationChanged === true);
    if (dedicationChanges && dedicationChanges.length >= maxDedicationChanges) {
      dedicationReasons.push("max-dedication-changes-reached");
    }

    const dedicationEnabled = DADUtils.countProcesses(activeProcesses, dedicationNoCompat) <= 0
      && (
        dedicationReasons.length <= 0
        || (customPermissionsValid && student.customPermissions.permissions.requestDedication)
      );

    /**
     * DIRECTION No active processes && permission of the status is Ok
     */
    const directionNoCompat = [
      Processes.cds,
      Processes.dedication,
      Processes.direction,
      Processes.industrial,
      Processes.thesis,
      Processes.permanentLeave
    ]
    const directionReasons = [];
    DADUtils.activeProcessesMsgs(activeProcesses, directionNoCompat).forEach(r => directionReasons.push(r));
    if (!student.permissions[PermissionsType.requestDirection]) {
      directionReasons.push(student.state + "_INFO");
    }

    // check if stduent deadline is less than 6 months
    const deadlineLessThan6M = student.deadline && moment(student.deadline).isSameOrBefore(moment().add(6, 'months'), 'd');
    if (deadlineLessThan6M) {
      directionReasons.push("deadline-less-than-6-months");
    }

    // check if is in evaluation period
    const isInEvaluation = DADUtils.isInEvaluation(student.program);
    if (isInEvaluation && !directionAllowed) {
      directionReasons.push("no-direction-in-evaluation-period");
    }

    const directionEnabled = DADUtils.countProcesses(activeProcesses, directionNoCompat) <= 0
      && (
        directionReasons.length <= 0
        || (customPermissionsValid && student.customPermissions.permissions.requestDirection)
      );

    /**
     * EXTENSION No active processes && permission of the status is Ok
     * && number of extension < (rd99 ? 2 : 1)
     */
    const extensionNoCompat = [
      Processes.cds,
      Processes.extension,
      Processes.thesis,
      Processes.permanentLeave
    ]
    let extensionReasons = [];
    const maxExtensions = student.rd === RDType.rd99 ? 2 : 1;
    DADUtils.activeProcessesMsgs(activeProcesses, extensionNoCompat).forEach(r => extensionReasons.push(r));
    if (!student.permissions[PermissionsType.requestExtension]) {
      extensionReasons.push(student.state + "_INFO");
    }
    if (student.extensions.length >= maxExtensions) {
      extensionReasons = [student.rd === RDType.rd99 ? "max-extensions-reached" : "extension-already-granted"];
    }
    const extensionEnabled = DADUtils.countProcesses(activeProcesses, extensionNoCompat) <= 0
      && (
        extensionReasons.length <= 0
        || (customPermissionsValid && student.customPermissions.permissions.requestExtension)
      );

    /**
     * LEAVE No active processes && permission of the status is Ok
     */
    const leaveNoCompat = [
      Processes.cds,
      Processes.leave,
      Processes.thesis,
      Processes.permanentLeave
    ]
    const leaveReasons = [];
    DADUtils.activeProcessesMsgs(activeProcesses, leaveNoCompat).forEach(r => leaveReasons.push(r));
    if (!student.permissions[PermissionsType.requestMedicalLeave] && !student.permissions[PermissionsType.requestTemporalLeave]) {
      leaveReasons.push(student.state + "_INFO");
    }
    const leaveEnabled = DADUtils.countProcesses(activeProcesses, leaveNoCompat) <= 0
      && (
        leaveReasons.length <= 0
        || (customPermissionsValid && student.customPermissions.permissions.requestMedicalLeave && student.customPermissions.permissions.requestTemporalLeave)
      );

    /**
     * EVALUATION No active processes && permission of the status is Ok
     * && already evaluated
     * && is in evaluation period
     */
    const evaluationNoCompat = [
      Processes.cds,
      Processes.direction,
      Processes.industrial,
      Processes.evaluation,
      Processes.thesis,
      Processes.permanentLeave
    ]
    const evaluationReasons = [];
    DADUtils.activeProcessesMsgs(activeProcesses, evaluationNoCompat).forEach(r => evaluationReasons.push(r));
    if (!student.permissions[PermissionsType.requestEvaluation]) {
      evaluationReasons.push(student.state + "_INFO");
    }
    const currentEvaluation = DADUtils.lastEvaluation(new Date());
    const alreadyEvaluated = student.evaluations.find(e => e.year === currentEvaluation.year && e.period === currentEvaluation.period);
    if (alreadyEvaluated) {
      evaluationReasons.push("already-evaluated");
    }

    if (!isInEvaluation) {
      evaluationReasons.push("outOfEvaluationPeriod");
    }

    const evaluationEnabled = DADUtils.countProcesses(activeProcesses, evaluationNoCompat) <= 0
      && (
        evaluationReasons.length <= 0
        || (customPermissionsValid && student.customPermissions.permissions.requestEvaluation));

    /**
     * STAY has CDS && permission of the status is Ok
     */
    const stayReasons = [];
    if (!student.cds) {
      stayReasons.push("no-cds");
    }
    const stayEnabled = stayReasons.length <= 0 || (customPermissionsValid && student.customPermissions.permissions.requestStay);

    return {
      thesis: {
        enabled: thesisEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(thesisReasons)
      },
      cds: {
        enabled: cdsEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(cdsReasons)
      },
      dedication: {
        enabled: dedicationEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(dedicationReasons)
      },
      direction: {
        enabled: directionEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(directionReasons)
      },
      extension: {
        enabled: extensionEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(extensionReasons)
      },
      leave: {
        enabled: leaveEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(leaveReasons)
      },
      evaluation: {
        enabled: evaluationEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(evaluationReasons)
      },
      permanentLeave: {
        enabled: true,
        reasons: []
      },
      stay: {
        enabled: stayEnabled,
        reasons: DADUtils.reasonsRemoveDuplicates(stayReasons)
      }
    }

  }

  static countProcesses(activeProcesses: IActiveProcesses, types?: Processes[]) {
    if (!types) types = <Processes[]>Object.keys(Processes);
    return Object.keys(activeProcesses).reduce((prev, key) => {
      if (!types.includes(<Processes>key) || key === "validation") return prev;
      return prev + activeProcesses[key];
    }, 0)
  }

  private static activeProcessesMsgs(activeProcesses: IActiveProcesses, noCompat?: Processes[]): string[] {
    let res = []
    if ((!noCompat || noCompat.includes(Processes.cds)) && activeProcesses[Processes.cds] > 0) {
      res.push("cds-started");
    }
    if ((!noCompat || noCompat.includes(Processes.dedication)) && activeProcesses[Processes.dedication] > 0) {
      res.push("dedication-started");
    }
    if ((!noCompat || noCompat.includes(Processes.direction)) && activeProcesses[Processes.direction] > 0) {
      res.push("direction-started");
    }
    if ((!noCompat || noCompat.includes(Processes.industrial)) && activeProcesses[Processes.industrial] > 0) {
      res.push("industrial-started");
    }
    if ((!noCompat || noCompat.includes(Processes.discharge)) && activeProcesses[Processes.discharge] > 0) {
      res.push("discharge-started");
    }
    if ((!noCompat || noCompat.includes(Processes.renewal)) && activeProcesses[Processes.renewal] > 0) {
      res.push("renewal-started");
    }
    if ((!noCompat || noCompat.includes(Processes.evaluation)) && activeProcesses[Processes.evaluation] > 0) {
      res.push("evaluation-started");
    }
    if ((!noCompat || noCompat.includes(Processes.extension)) && activeProcesses[Processes.extension] > 0) {
      res.push("extension-started");
    }
    if ((!noCompat || noCompat.includes(Processes.leave)) && activeProcesses[Processes.leave] > 0) {
      res.push("leave-started");
    }
    if ((!noCompat || noCompat.includes(Processes.thesis)) && activeProcesses[Processes.thesis] > 0) {
      res.push("thesis-started");
    }

    return res;
  }

  private static reasonsRemoveDuplicates(reasons: string[]) {
    let result = reasons.filter(r => true);
    if (result.includes('thesis-started')) {
      result = result.filter(r => r !== StudentStateType.processingRequested + "_INFO")
      result = result.filter(r => r !== StudentStateType.processingPendingRectification + "_INFO")
      result = result.filter(r => r !== StudentStateType.processing + "_INFO")
      result = result.filter(r => r !== StudentStateType.processingAccepted + "_INFO")
      result = result.filter(r => r !== StudentStateType.defenceRequested + "_INFO")
      result = result.filter(r => r !== StudentStateType.defenceAccepted + "_INFO")
    }
    if (result.includes('cds-started')) {
      result = result.filter(r => r !== StudentStateType.initial + "_INFO")
    }
    if (result.includes('leave-started')) {
      result = result.filter(r => r !== StudentStateType.temporalLeave + "_INFO")
      result = result.filter(r => r !== StudentStateType.medicalLeave + "_INFO")
    }
    if (result.includes("deadline-is-over")) {
      result = result.filter(r => r !== StudentStateType.deadline + "_INFO")
    }
    return result;
  }

  static validDisabilityDates(verdeStart: Date, verdeEnd: Date, disabilities: IDisability[]): boolean {
    for (const azul of disabilities) {
      if (
        (!verdeEnd && !azul.end)
        || (!verdeEnd && azul.end && (moment(verdeStart).isSameOrBefore(azul.start, 'd') || moment(verdeStart).isSameOrBefore(azul.end, 'd')))
        || (verdeEnd && !azul.end && (moment(azul.start).isSameOrBefore(verdeStart, 'd') || moment(azul.start).isSameOrBefore(verdeEnd, 'd')))
        || (verdeEnd && azul.end && (moment(verdeStart).isBetween(azul.start, azul.end, 'd', "[]") || moment(verdeEnd).isBetween(azul.start, azul.end, 'd', "[]")) || moment(azul.start).isBetween(verdeStart, verdeEnd, 'd', "[]") || moment(azul.end).isBetween(verdeStart, verdeEnd, 'd', "[]"))
      ) {
        return false;
      }
    }

    return true;
  }


}


export interface IStateProccesses {
  thesis: {
    enabled: boolean,
    reasons: string[]
  };
  cds: {
    enabled: boolean,
    reasons: string[]
  };
  dedication: {
    enabled: boolean,
    reasons: string[]
  };
  direction: {
    enabled: boolean,
    reasons: string[]
  };
  extension: {
    enabled: boolean,
    reasons: string[]
  };
  leave: {
    enabled: boolean,
    reasons: string[]
  };
  permanentLeave: {
    enabled: boolean,
    reasons: string[]
  };
  evaluation: {
    enabled: boolean,
    reasons: string[]
  };
  stay: {
    enabled: boolean,
    reasons: string[]
  }
}