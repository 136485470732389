import { IDocumentRef } from "./IDocument";

export interface IUser {
  _id?: string,
  uid?: string,
  altUids?: string[],
  idNum: string,
  altIdNums?: string[],
  firstName: string,
  lastName: string,
  email: IEmail,
  altEmails?: IEmail[],
  favEmail?: IEmail,
  emailNotifications: boolean,
  phone?: number,
  altPhones?: number[],
  address?: string,
  blocked: boolean,
  roles: string[],
  selectedRole?: string,
  university?: string, // TODO internal users university? origin?
  goodPracticeCodeAccepted?: boolean,
  onlyCoSupervisor?: boolean,
  cpAgreement?: IDocumentRef,

  // virtuals
  fullName?: string,
  search?: string,
  program?: string

  // UI
  added?: boolean
}

export interface IEmail {
  address: string,
  validated: boolean
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum Role {
  admin = "admin",
  service = "service",
  student = "student",
  supervisor = "supervisor",
  supervisor1 = "supervisor1",
  supervisor2 = "supervisor2",
  supervisor3 = "supervisor3",
  tutor = "tutor",
  directionTeam = "directionTeam", // supervisor or tutor
  academicCommittee = "academicCommittee",
  coordinator = "coordinator",
  postgraduateCommittee = "postgraduateCommittee",
  postgraduateCommitteeRepresentative = "postgraduateCommitteeRepresentative",
  postgraduateCommitteeSecretary = "postgraduateCommitteeSecretary",
  postgraduateCommitteePresident = "postgraduateCommitteePresident",
  doctoralSchoolPrincipal = "doctoralSchoolPrincipal",
  secretary = "secretary",
  chiefSecretary = "chiefSecretary",
  facultyPrincipal = "facultyPrincipal",
  evaluationCommitteeMember = "evaluationCommitteeMember",
  thesisCommittee = "thesisCommittee",
  thesisCommitteePresident = "thesiscommitteePresident",
  thesisCommitteeSecretary = "thesiscommitteeSecretary",
  thesisCommitteeMember = "thesiscommitteeMember",
  thesisCommitteeAlt = "thesiscommitteeAlt",
  thesisExternalReviewer = "thesisExternalReviewer",
  thesisExternalReviewer1 = "thesisExternalReviewer1",
  thesisExternalReviewer2 = "thesisExternalReviewer2",
  thesisExternalReviewer3 = "thesisExternalReviewer3",
  modifyEvaluations = "modifyEvaluations",
  qa = "qa",
  documentAccessor = "documentAccessor",
  studentRepresentative = "studentRepresentative",
}

export enum RolePriority {
  service = 1,
  student = 2,
  coordinator = 3,
  secretary = 4,
  chiefSecretary = 5,
  tutor = 6,
  supervisor = 7
}

export enum Group {
  global = "global",
  faculty = "faculty",
  student = "student",
  program = "program",
  evaluationCommittee = "evaluationCommittee"
}

export const COUNTRIES = [
  "España", "Acrotiri y Dhekelia", "Afganistán", "Abjasia", "Albania", "Alemania", "Andorra",
  "Angola", "Anguila", "Antigua y Barbuda", "Arabia Saudita", "Argelia", "Argentina",
  "Armenia", "Aruba", "Australia", "Austria", "Azerbaiyán", "Bahamas", "Baréin", "Bangladés",
  "Barbados", "Bélgica", "Belice", "Benín", "Bermudas", "Bielorrusia", "Birmania", "Bolivia",
  "Bosnia y Herzegovina", "Botsuana", "Brasil", "Brunéi", "Bulgaria", "Burkina Faso", "Burundi",
  "Bután", "Cabo Verde", "Camboya", "Camerún", "Canadá", "Chad", "Chile", "China", "Chipre",
  "Colombia", "Comoras", "República del Congo", "Corea del Norte", "Corea del Sur",
  "Costa de Marfil", "Costa Rica", "Croacia", "Cuba", "Curazao", "Dinamarca", "Dominica",
  "Ecuador", "Egipto", "El Salvador", "Ciudad del Vaticano", "Emiratos Árabes Unidos", "Eritrea",
  "Eslovaquia", "Eslovenia", "Estados Unidos", "Estonia", "Etiopía", "Filipinas",
  "Finlandia", "Fiyi", "Francia", "Gabón", "Gambia", "Georgia", "Ghana", "Gibraltar", "Granada",
  "Grecia", "Groenlandia", "Guam", "Guatemala", "Guemsey", "Guinea", "Guinea-Bissau",
  "Guinea Ecuatorial", "Guyana", "Haití", "Honduras", "Hong Kong", "Hungría", "India",
  "Indonesia", "Irak", "Irán", "Irlanda", "Isla Bouvet", "Isla Christmas",
  "Isla Norfolk", "Islandia", "Islas Caimán", "Islas Cocos", "Islas Cook",
  "Islas Feroe", "Islas Heard y McDonald", "Islas Malvinas", "Islas Marianas del Norte",
  "Islas Marshall", "Islas Pitcaim", "Islas Salomón", "Islas Turcas y Caicos",
  "Islas Vírgenes Británicas", "Israel", "Italia", "Jamaica", "Jan Mayen", "Japón", "Jersey",
  "Jordania", "Kazajistán", "Kenia", "Kirguistán", "Kiribati", "Kosovo", "Kuwait", "Laos",
  "Lesoto", "Letonia", "Líbano", "Liberia", "Libia", "Liechtenstein", "Lituania", "Luxemburgo",
  "Macao", "Macedonia", "Madagascar", "Malasia", "Malaui", "Maldivas", "Malí", "Malta",
  "Isla de Man", "Marruecos", "Mauricio", "Mauritania", "Mayotte", "México", "Micronesia",
  "Moldavia", "Mónaco", "Mongolia", "Montenegro", "Montserrat", "Mozambique",
  "República de Nagorno Karajab", "Namibia", "Nauru", "Nepal", "Nicaragua", "Níger", "Nigeria",
  "Niue", "Noruega", "Nueva Caledonia", "Nueva Zelanda", "Omán", "Osetia del Sur", "Países Bajos",
  "Pakistán", "Palaos", "Palestina", "Panamá", "Papúa-Nueva Guinea", "Paraguay", "Perú",
  "Polinesia Francesa", "Polonia", "Portugal", "Puerto Rico", "Qatar", "Reino Unido",
  "República Centroafricana", "República Checa", "República Dominicana", "Ruanda", "Rumania",
  "Rusia", "Sahara Occidental", "Samoa", "Samoa Americana", "San Bartolomé", "San Cristóbal y Nieves",
  "San Marino", "San Martín (Francia)", "San Martín (Países Bajos)", "San Pedro y Miquelón",
  "San Vicente y las Granadinas", "Santa Lucía", "Santo Tomé y Príncipe", "Senegal", "Serbia",
  "Seychelles", "Sierra Leona", "Singapur", "Siria", "Somalia", "Somalilandia", "Sri Lanka",
  "Suazilandia", "Sudáfrica", "Sudán", "Sudán del Sur", "Suecia", "Suiza", "Surinam", "Svalbard",
  "Tailandia", "Taiwán", "Tanzania", "Tayikistán", "Timor Oriental", "Togo", "Tokelau", "Tonga",
  "Transnistria", "Trinidad y Tobago", "Túnez", "Turkmenistán", "Turquía", "Tuvalu", "Ucrania",
  "Uganda", "Uruguay", "Uzbekistán", "Vanuatu", "Venezuela", "Vietnam", "Wallis y Futuna", "Yemen",
  "Yibuti", "Zambia", "Zimbabue"
]