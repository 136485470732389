import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IDocumentRef } from "../../../interfaces/IDocument";
import { IStayProcess } from "../../../interfaces/IStayProcess";

@Injectable()
export class StayHttpService {
  constructor(private http: HttpClient) { }

  request(studentId: string, data: {
    university: string,
    contactName: string,
    contactEmail: string,
    startDate: Date,
    endDate: Date,
    justification: IDocumentRef[]
  }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/stay/request/${studentId}`, data);
  }

  directionTeamValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/stay/directionTeamValidation/${taskId}`, {});
  }

  directionTeamDeny(taskId: string, data: { denialMsg: string, end: boolean }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/stay/directionTeamDeny/${taskId}`, data);
  }

  acValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/stay/acValidate/${taskId}`, {});
  }

  acDeny(taskId: string, data: { denialMsg: string, end: boolean }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/stay/acDeny/${taskId}`, data);
  }

  studentReview(taskId: string, data: {
    university: string,
    contactName: string,
    contactEmail: string,
    startDate: Date,
    endDate: Date,
    justification: IDocumentRef[]
  }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/stay/studentReview/${taskId}`, data);
  }

  findProcess(taskId: string): Observable<IStayProcess> {
    return this.http.get<IStayProcess>(`/api/stay/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<IStayProcess[]> {
    return this.http.get<IStayProcess[]>(`/api/stay/process/${studentId}`);
  }

}