<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <denial-msgs-view [denialMsgs]="process.denialMsgs"></denial-msgs-view>

  <stay-request-form [disabled]="disabled"
    [sending]="sending"
    [data]="data"
    (onSend)="send($event)"></stay-request-form>

</ng-container>