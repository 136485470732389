<div class="list-group"
  *ngIf="stays && stays.length > 0">
  <div class="list-group-item d-flex justify-content-between align-items-center"
    *ngFor="let stay of stays">

    <div style="max-width: 200px;">
      {{ stay.university }}
      <div *ngIf="stay.justification || stay.document">
        <div *ngFor="let doc of stay.justification">
          <document-link [document]="doc"></document-link>
        </div>
        <div *ngIf="stay.document">
          <document-link [document]="stay.document"></document-link>
        </div>
        <div *ngIf="stay.confirmationDoc">
          <document-link [document]="stay.confirmationDoc"></document-link>
        </div>
      </div>
    </div>

    <div class="nowrap">
      {{ stay.startDate | moment:'D/M/YY' }} - {{ stay.endDate | moment:'D/M/YY' }}
    </div>

    <div>
      <div class="badge badge-dark"
        *ngIf="!stay.confirmationDoc">
        Pte. acreditar
      </div>
      <div class="badge badge-info"
        *ngIf="stay.confirmationDoc">
        Acreditada
      </div>

      <div class="w-100 text-center mt-1"
        *ngIf="us.isStudent()">
        <button class="btn btn-sm"
          [class.btn-outline-secondary]="!stay.confirmationDoc"
          [class.btn-outline-dark]="stay.confirmationDoc"
          (click)="openStayConfirm(stay)">
          Acreditación
        </button>
      </div>
    </div>


  </div>

</div>
<div class="card-body pt-0"
  *ngIf="stays && stays.length <= 0">
  <i>
    {{ 'no-stays' | translate }}
  </i>
</div>

<modal [(open)]="open"
  [title]="'Acreditación de estancia'">

  <ng-container *ngIf="stay">
    <div class="mb-3">
      <div class="small text-muted mb-1">
        {{ 'university-investigation-centre' | translate}}
      </div>
      <div>
        {{ stay.university }}
      </div>
    </div>

    <div class="mb-3">
      <div class="small text-muted mb-1">
        {{ 'contact-person' | translate}}
      </div>
      <div>
        {{ stay.contactName }}
      </div>
      <div>
        <a [href]="'mailto:' + stay.contactEmail">
          {{ stay.contactEmail }}
        </a>
      </div>
    </div>

    <div class="mb-3">
      <div class="small text-muted mb-1">
        {{ 'start-date' | translate}}
      </div>
      <div>
        {{ stay.startDate | moment: 'DD/MM/YYYY' }}
      </div>
    </div>

    <div class="mb-3">
      <div class="small text-muted mb-1">
        {{ 'end-date' | translate}}
      </div>
      <div>
        {{ stay.endDate | moment: 'DD/MM/YYYY' }}
      </div>
    </div>
  </ng-container>

  <form #f="ngForm"
    id="confirmStayForm"
    (ngSubmit)="saveConfirm()"
    *ngIf="open">

    <div class="form-group">
      <label for="startDate">
        {{ 'start-date' | translate }}
      </label>

      <date-picker id="startDate"
        name="startDate"
        [placeholder]="'start-date' | translate"
        required="true"
        [isInvalid]="startDateInput.invalid && f.submitted"
        #startDateInput="ngModel"
        [time]="false"
        [(ngModel)]="data.startDate"> </date-picker>
      <validation-errors [control]="startDateInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="endDate">
        {{ 'end-date' | translate }}
      </label>

      <date-picker id="endDate"
        name="endDate"
        required="true"
        [placeholder]="'end-date' | translate"
        [isInvalid]="endDateInput.invalid && f.submitted"
        #endDateInput="ngModel"
        [time]="false"
        [(ngModel)]="data.endDate"> </date-picker>
      <validation-errors [control]="endDateInput"
        [show]="f.submitted"></validation-errors>
      <span class="invalid-feedback d-inline"
        *ngIf="data.startDate && data.endDate && !validDateRange()">
        {{ 'min-stay-duration-30-days' | translate }}
      </span>
    </div>


    <div class="form-group">
      <label>
        {{ 'confirmationDoc' | translate }}
      </label>

      <file-input name="confirmationDoc"
        [required]="false"
        #confirmationDocInput="ngModel"
        [(ngModel)]="data.confirmationDoc"></file-input>
      <small class="form-text text-muted">
        {{ 'stay-confirmationDoc-note' | translate }}
      </small>
      <validation-errors [show]="f.submitted"
        [control]="confirmationDocInput"></validation-errors>
    </div>

    <div class="form-group text-right">
      <div class="d-block invalid-feedback mb-2"
        *ngIf="f.invalid && f.submitted">
        {{ 'check-errors' | translate }}
      </div>
    </div>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="confirmStayForm">{{ 'save' | translate }}</button>

  </div>

</modal>