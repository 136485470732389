<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'request-date' | translate }}
  </div>
  <div>
    {{ process.createdAt | date: 'dd/MM/yyyy HH:mm' }}
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'university-investigation-centre' | translate}}
  </div>
  <div>
    {{ process.university }}
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'contact-person' | translate}}
  </div>
  <div>
    {{ process.contactName }}
  </div>
  <div>
    <a [href]="'mailto:' + process.contactEmail">
      {{ process.contactEmail }}
    </a>
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'start-date' | translate}}
  </div>
  <div>
    {{ process.startDate | moment: 'DD/MM/YYYY' }}
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'end-date' | translate}}
  </div>
  <div>
    {{ process.endDate | moment: 'DD/MM/YYYY' }}
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'justification' | translate}}
  </div>
  <div *ngFor="let doc of process.justification">
    <document-link [document]="doc"></document-link>
  </div>
</div>