<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <stay-process-data-view [process]="process"></stay-process-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="manageSend()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <div *ngIf="validation.validate === false">
      <!-- two radio buttons to choose end variable (true or false) -->
      <div class="form-group">
        <label>{{ 'choose-an-option' | translate }}</label>
        <div class="form-check">
          <input class="form-check
            form-check-input"
            type="radio"
            name="endVariable"
            id="endVariableTrue"
            [value]="true"
            [(ngModel)]="end"
            required>
          <label class="form-check-label"
            for="endVariableTrue">
            {{ 'reject-and-end-process' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check
            form-check-input"
            type="radio"
            name="endVariableFalse"
            id="endVariableFalse"
            [value]="false"
            [(ngModel)]="end"
            required>
          <label class="form-check-label"
            for="endVariableFalse">
            {{ 'reject-and-back-to-student' | translate }}
          </label>
        </div>
        <span class="invalid-feedback d-inline"
          *ngIf="f.submitted && (end === null || end === undefined)">
          El campo es obligatorio
        </span>
      </div>
    </div>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>
</ng-container>