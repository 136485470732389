import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IDocument, IDocumentRef } from '../../../../interfaces/IDocument';

@Injectable()
export class DocumentService {

  csv: string;
  externalTask: { _id: string, token: string };
  studentId: string;

  constructor(private http: HttpClient) {

  }

  public upload(data): Observable<{ status: string, progress?: number, res?: any, event?: HttpEvent<any> }> {
    let url;
    if (this.externalTask) {
      url = `/api/etask/upload?studentId=${this.studentId}&taskId=${this.externalTask._id}&token=${this.externalTask.token}`;
    } else {
      url = '/api/document/upload';
      if (this.studentId) {
        url += '?studentId=' + this.studentId;
      }
    }
    return this.http.post(url, data, {
      reportProgress: true,
      observe: 'events',
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.Sent:
        case HttpEventType.ResponseHeader:
        case HttpEventType.User:
        case HttpEventType.DownloadProgress:
          return { status: 'none' };

        case HttpEventType.UploadProgress: {
          let progress = Math.round(100 * event.loaded / event.total);
          return { status: 'uploading', progress };
        }

        case HttpEventType.Response:
          return { status: 'uploaded', res: event.body };

        default:
          return { status: 'unhandled-event-type', event };
      }
    }));
  }

  saveDetails(documentId, location, description): Observable<any> {
    return this.http.put<any>(`/api/document/details/${documentId}`, { location, description });
  }

  public downloadLink(documentId) {
    if (this.externalTask) {
      return `${window.location.origin}/api/etask/download/${documentId}/${this.externalTask._id}/${this.externalTask.token}`;
    } else if (this.csv) {
      return `${window.location.origin}/api/document/csv/download/${documentId}/${this.csv}`;
    } else {
      return `${window.location.origin}/api/document/download/${documentId}`;
    }
  }

  public downloadLinkVn(documentId, vn) {
    return `${window.location.origin}/api/document/download/${documentId}/${vn}`;
  }

  public metadata(documentId): Observable<IDocument> {
    return this.http.get<IDocument>(`/api/document/metadata/${documentId}`);
  }

  public download(documentId) {
    window.location.href = this.downloadLink(documentId);
  }

  public downloadVn(documentId, vn) {
    window.location.href = this.downloadLinkVn(documentId, vn);
  }

  getDocument(documentId: string) {
    return this.http.get<IDocument>(`/api/document/metadata/${documentId}`);
  }

  verify(csv: string) {
    return this.http.get<IDocumentRef>(`/api/document/csv/${csv}`)
  }

}