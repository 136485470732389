import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import * as moment from 'moment-timezone';
import { HelperService } from '../../../../../shared/helper-service/helper.service';

@Component({
  selector: 'stay-request-form',
  templateUrl: './stay-request-form.component.html',
})
export class StayRequestFormComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() sending;
  @Input() disabled;
  @Output() onSend = new EventEmitter<{
    justification: IDocumentRef[];
    startDate: Date;
    endDate: Date;
    university: string;
    contactName: string;
    contactEmail: string;
  }>();

  @Input() data: {
    justification: IDocumentRef[];
    startDate: Date;
    endDate: Date;
    university: string;
    contactName: string;
    contactEmail: string;
  } = {
      justification: [],
      startDate: null,
      endDate: null,
      university: '',
      contactName: '',
      contactEmail: ''
    }

  constructor(public hs: HelperService) { }

  ngOnInit() {
  }

  send() {
    if (!this.f.valid || !this.validDateRange()) {
      return;
    }

    this.onSend.emit(this.data);
  }

  validDateRange() {
    if (this.data.startDate && this.data.endDate) {
      return moment(this.data.endDate).isAfter(this.data.startDate) && moment(this.data.endDate).diff(this.data.startDate, 'days', false) >= 29
    }

    return false;
  }


}
