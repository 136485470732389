import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { IUser } from '../../../../../../interfaces/IUser';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';
import { UserSelectorComponent } from '../../../../UI/user-selector/user-selector.component';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../../user/user.service';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'main-roles',
  templateUrl: './main-roles.component.html',
})
export class MainRolesComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('addUserSelector') addUserSelector: UserSelectorComponent;
  @ViewChild('addQAUserSelector') addQAUserSelector: UserSelectorComponent;
  @ViewChild('addDocumentAccessorUserSelector') addDocumentAccessorUserSelector: UserSelectorComponent;

  @ViewChild('f') f: NgForm;

  postgraduateCommitteeSecretary: IUser;
  postgraduateCommitteePresident: IUser;
  doctoralSchoolPrincipal: IUser;
  serviceUsers: IUser[] = [];
  qaUsers: IUser[] = [];
  documentAccessorUsers: IUser[] = [];

  addUser: IUser;
  addQAUser: IUser;
  addDocumentAccessorUser: IUser;

  ready = false;

  saved = true;

  constructor(private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    private loading: LoadingIndicatorService,
    public us: UserService,
    private cds: ConfirmationDialogService) { }

  ngOnInit() {
    this.loading.start();
    this.ss.getRoles().subscribe(res => {
      this.postgraduateCommitteePresident = res.postgraduateCommitteePresident;
      this.postgraduateCommitteeSecretary = res.postgraduateCommitteeSecretary;
      this.doctoralSchoolPrincipal = res.doctoralSchoolPrincipal;
      this.serviceUsers = res.serviceUsers;
      this.qaUsers = res.qaUsers;
      this.documentAccessorUsers = res.documentAccessorUsers

      this.ready = true;
      this.loading.stop();
    })
  }

  save() {
    if (!this.f.valid) return;

    this.ss.setRoles({
      postgraduateCommitteePresidentId: this.postgraduateCommitteePresident._id,
      postgraduateCommitteeSecretaryId: this.postgraduateCommitteeSecretary._id,
      doctoralSchoolPrincipalId: this.doctoralSchoolPrincipal._id,
      serviceUsers: this.serviceUsers.map(u => u._id),
      qaUsers: this.qaUsers.map(u => u._id),
      documentAccessorUsers: this.documentAccessorUsers.map(u => u._id)
    }).subscribe(() => {
      this.alerts.saveSuccess();
      this.saved = true;
    })
  }

  removeService(usr: IUser) {
    this.serviceUsers = this.serviceUsers.filter(u => u._id.toString() !== usr._id.toString())
    this.saved = false;
  }

  addService() {
    if (!this.addUser) return;
    this.serviceUsers.push(this.addUser);
    this.addUser = null;
    this.addUserSelector.resetFind();
    this.addUserSelector.email = '';
    this.saved = false;
  }

  removeQA(usr: IUser) {
    this.qaUsers = this.qaUsers.filter(u => u._id.toString() !== usr._id.toString())
    this.saved = false;
  }

  addQA() {
    if (!this.addQAUser) return;
    this.qaUsers.push(this.addQAUser);
    this.addQAUser = null;
    this.addQAUserSelector.resetFind();
    this.addQAUserSelector.email = '';
    this.saved = false;
  }

  removeDocumentAccessor(usr: IUser) {
    this.documentAccessorUsers = this.documentAccessorUsers.filter(u => u._id.toString() !== usr._id.toString())
    this.saved = false;
  }

  addDocumentAccessor() {
    if (!this.addDocumentAccessorUser) return;
    this.documentAccessorUsers.push(this.addDocumentAccessorUser);
    this.addDocumentAccessorUser = null;
    this.addDocumentAccessorUserSelector.resetFind();
    this.addDocumentAccessorUserSelector.email = '';
    this.saved = false;
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.saved) return true;
    return confirm();
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.saved) return true;
    const p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: 'Existena cambios sin guardar',
        message: '¿Está seguro que desea salir sin guardar? Los cambios se perderán.',
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

  thesisPublicationsReport() {
    window.location.href = `${window.location.origin}/api/documentAccessor/thesisPublicationsReport`
  }

}
