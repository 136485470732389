import { IDocumentRef } from "./IDocument";
import { IProcess, IValidationMsgs } from "./IProcess";
import { IStudent } from "./IStudent";

export interface IStayProcess extends IProcess {
  studentId: string,
  state: StayPState,
  university: string,
  contactName: string,
  contactEmail: string,
  startDate: Date,
  endDate: Date,
  justification: IDocumentRef[],

  denialMsgs?: IValidationMsgs,

  // POPULATE
  student?: IStudent
}

export enum StayPState {
  PendingStudentReview = "PendingStudentReview",
  PendingDirectionTeamValidation = "PendingDirectionTeamValidation",
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation",
}

export enum TKStay {
  studentReview = "studentReview",
  directionTeamValidation = "directionTeamValidation",
  academicCommitteeValidation = "academicCommitteeValidation",
}