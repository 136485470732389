import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IStay } from '../../../../../../../interfaces/IStudent';
import { UserService } from '../../../../../user/user.service';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import * as moment from 'moment-timezone';
import { StudentHttpService } from '../../../../../data-services/student-http.service';
import { NgForm } from '@angular/forms';
import { StudentRootService } from '../../../../routes/student/student-root/student-root.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';

@Component({
  selector: 'stays-list',
  templateUrl: './stays-list.component.html',
})
export class StaysListComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() stays: IStay[] = [];

  open = false;

  stay: IStay;
  data: {
    confirmationDoc: IDocumentRef;
    startDate: Date;
    endDate: Date;
  } = {
      confirmationDoc: null,
      startDate: null,
      endDate: null,
    }

  moment = moment;

  constructor(public us: UserService,
    private srs: StudentRootService,
    private ss: StudentHttpService,
    private alerts: PopAlertsService
  ) { }

  ngOnInit() {
  }

  validDateRange() {
    if (this.data.startDate && this.data.endDate) {
      return moment(this.data.endDate).isAfter(this.data.startDate) && moment(this.data.endDate).diff(this.data.startDate, 'days', false) >= 29
    }

    return false;
  }

  openStayConfirm(stay: IStay) {
    this.stay = stay;
    this.data.startDate = stay.startDate;
    this.data.endDate = stay.endDate;
    this.data.confirmationDoc = stay.confirmationDoc;
    this.open = true;
  }

  saveConfirm() {
    if (!this.f.valid || !this.validDateRange()) {
      return;
    }

    this.ss.confirmStay(this.srs.student._id, this.stay._id, {
      confirmationDoc: this.data.confirmationDoc,
      startDate: this.data.startDate,
      endDate: this.data.endDate
    }).subscribe(() => {
      this.alerts.saveSuccess();
      // update stay in stays list:

      this.stay.startDate = this.data.startDate;
      this.stay.endDate = this.data.endDate;
      this.stay.confirmationDoc = this.data.confirmationDoc;

      this.open = false;
    });
  }

}
