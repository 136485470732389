import { IDocumentRef } from './IDocument';
import { IStudent } from './IStudent';

export interface IActivity {
  _id?: string,
  studentId: string,
  order: number,
  title: string,
  description?: string,
  activityType: ActivityType,
  startDate?: Date,
  endDate?: Date,
  createdAt: Date,
  validated: boolean,
  evaluated: boolean,
  validationState: ActivityValidationStateType,
  validationMsg?: string,
  attached?: IDocumentRef[],
  deleted: boolean,

  // additional data
  /**
   * DOI/enlace a la publicación (obligado)

ISSN/ISBN (obligado)

Año de aceptación/prepublicación (obligatorio)

Año de publicación definitiva
   */

  doi?: string,
  issn?: string,
  pubYear?: number,
  defPubYear?: number,

  // Populate
  student?: IStudent,
}

export enum ActivityType {
  Educational = "Educational",
  InternationalConference = "InternationalConference",
  NationalConference = "NationalConference",
  SeminarConference = "SeminarConference",
  ThesisDefence = "ThesisDefence",
  StayNational = "StayNational",
  StayInternational = "StayInternational",
  HighQPub = "HighQPub",
  UncreditedPub = "UncreditedPub",
  Others = "Others",
  AnnualEvaluation = "AnnualEvaluation",
  AcceptanceForProcessing = "AcceptanceForProcessing",
}

export enum ActivityValidationStateType {
  Draft = "Draft",
  PendingTutorValitation = "PendingTutorValidation",
  PendingStudentReview = "PendingStudentReview",
  Validated = "Validated"
}
