import { Component, Input, OnInit } from '@angular/core';
import { IStayProcess } from '../../../../../../../interfaces/IStayProcess';

@Component({
  selector: 'stay-process-data-view',
  templateUrl: './stay-process-data-view.component.html',
})
export class StayProcessDataViewComponent implements OnInit {

  @Input() process: IStayProcess;

  constructor() { }

  ngOnInit() {
  }

}
