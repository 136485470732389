import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EvaluationReason, IEvaluationControl } from '../../../interfaces/IEvaluationControl';
import { IEvaluationProcess } from '../../../interfaces/IEvaluationProcess';
import { EvaluationPeriodType, EvaluationType, IStudent } from '../../../interfaces/IStudent';
import { ITask } from '../../../interfaces/ITask';
import { IDocumentRef } from '../../../interfaces/IDocument';

@Injectable()
export class EvaluationControlHttpService {

  constructor(private http: HttpClient) { }

  mustEvaluate(studentId: string, programId: string, year: string, period: string): Observable<{ mustEvaluate: boolean }> {
    return this.http.get<{ mustEvaluate: boolean }>(`/api/evaluationControl/mustEvaluate/${studentId}/${programId}/${year}/${period}`);
  }

  directionChangeAllowed(studentId: string): Observable<{ allowed: boolean }> {
    return this.http.get<{ allowed: boolean }>(`/api/evaluationControl/directionChangeAllowed/${studentId}`);
  }

  find(programId: string, year: string, period: string): Observable<IEvaluationControl> {
    return this.http.get<IEvaluationControl>(`/api/evaluationControl/find/${programId}/${year}/${period}`);
  }

  projected(programId: string): Observable<{ included: { studentId: string, student: IStudent, reason: EvaluationReason }[], excluded: { studentId: string, student: IStudent, reason: EvaluationReason }[] }> {
    return this.http.get<{ included: { studentId: string, student: IStudent, reason: EvaluationReason }[], excluded: { studentId: string, student: IStudent, reason: EvaluationReason }[] }>(`/api/evaluationControl/projection/${programId}`);
  }

  processes(programId: string, year: string, period: string): Observable<IEvaluationProcess[]> {
    return this.http.get<IEvaluationProcess[]>(`/api/evaluationControl/processes/${programId}/${year}/${period}`);
  }

  findConfirmTasks(programId: string, year: string, period: string): Observable<ITask[]> {
    return this.http.get<ITask[]>(`/api/evaluationControl/findConfirmTasks/${programId}/${year}/${period}`);
  }

  evaluatedStudets(programId: string, year: string, period: string): Observable<IStudent[]> {
    return this.http.get<IStudent[]>(`/api/evaluationControl/evaluatedStudents/${programId}/${year}/${period}`);
  }

  addStudent(programId: string, evaluationControlId: string, studentId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluationControl/addStudent/${programId}/${evaluationControlId}/${studentId}`, {});
  }

  removeStudent(programId: string, evaluationControlId: string, studentId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluationControl/removeStudent/${programId}/${evaluationControlId}/${studentId}`, {});
  }

  addEvaluation(data: {
    studentId: string,
    registeredAt: Date,
    year: string,
    period: EvaluationPeriodType,
    evaluation: EvaluationType,
    presidentId: string,
    secretaryId: string,
    memberId: string,
    reports: IDocumentRef[],
    committeeReport?: IDocumentRef,
    researchPlan?: IDocumentRef,
    recordFile: IDocumentRef
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluationControl/evaluations/add`, { ...data });
  }

  editEvaluation(data: {
    studentId: string,
    evaluationId: string,
    registeredAt: Date,
    evaluation: EvaluationType,
    presidentId: string,
    secretaryId: string,
    memberId: string,
    reports: IDocumentRef[],
    committeeReport?: IDocumentRef,
    researchPlan?: IDocumentRef,
    recordFile: IDocumentRef
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluationControl/evaluations/edit`, { ...data });
  }

  removeEvaluation(data: {
    studentId: string,
    evaluationId: string,
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluationControl/evaluations/remove`, { ...data });
  }

}
