import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IStayProcess } from '../../../../../../../interfaces/IStayProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { StayHttpService } from '../../../../../data-services/stay-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { Router } from '@angular/router';

@Component({
  selector: 'stay-coordinator-validation',
  templateUrl: './stay-coordinator-validation.component.html',
})
export class StayCoordinatorValidationComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IStayProcess;
  end = null;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  constructor(private ss: StayHttpService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.ss.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid)
      return;

    if (this.validation.validate === false) {
      this.ss.acDeny(this.task._id, { denialMsg: this.validation.denialMsg, end: this.end }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      })
    } else {
      this.ss.acValidate(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      })
    }
  }

}
