import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';
import { Subscription, zip } from 'rxjs';
import { ILineOfResearch } from '../../../../../../interfaces/ILineOfResearch';
import { IUser } from '../../../../../../interfaces/IUser';
import { UserService } from '../../../../user/user.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { UserSelectorComponent } from '../../../../UI/user-selector/user-selector.component';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { NgTemplateOutlet } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { IProgram } from '../../../../../../interfaces/IProgram';
import * as _ from 'lodash';
import { IFaculty } from '../../../../../../interfaces/IFaculty';
import { FacultyHttpService } from '../../../../data-services/faculty-http.service';

@Component({
  selector: 'program-info',
  templateUrl: './program-info.component.html',
})
export class ProgramInfoComponent implements OnInit {
  @ViewChild('addAuthUserSelector') addAuthUserSelector: UserSelectorComponent;
  @ViewChild('addACUserSelector') addACUserSelector: UserSelectorComponent;
  @ViewChild('addSRUserSelector') addSRUserSelector: UserSelectorComponent;

  @ViewChild('removeLineConfirmationTemplate') removeLineConfirmationTemplate: NgTemplateOutlet;

  @ViewChild('editLineForm') editLineForm: NgForm;

  @Input() edit = false;
  @Input() program;

  faculties: IFaculty[] = [];

  linesOfResearch: ILineOfResearch[] = [];
  academicCommitteeMembers: IUser[] = [];
  modifyEvaluationsAuth: IUser[] = [];
  coordinator: IUser;
  studentRepresentatives: IUser[] = [];

  newLineResearch: string;

  lineToRemove: ILineOfResearch;
  linesOfResearchToSwitch: ILineOfResearch[] = [];
  newLineToSwithId: string;

  lineToEdit: ILineOfResearch;
  lineNewName: string;
  openEditLine = false;

  addAuthUser: IUser;

  addACUser: IUser;

  addSRUser: IUser;

  editOpen = false;
  programEdit: IProgram;

  ready = false;

  sub: Subscription;

  constructor(private ps: ProgramHttpService,
    private cds: ConfirmationDialogService,
    private loading: LoadingIndicatorService,
    private alerts: PopAlertsService,
    public us: UserService,
    private ts: TranslateService,
    private fs: FacultyHttpService) { }

  ngOnInit() {
    this.loading.start();

    this.sub = zip(this.ps.academicCommitteeMembers(this.program._id),
      this.ps.linesOfResearch(this.program._id),
      this.ps.modifyEvaluationsAuth(this.program._id),
      this.ps.fetchCoordinator(this.program._id),
      this.fs.fetchFaculties(),
      this.ps.studentRepresentatives(this.program._id)).subscribe((res) => {
        this.academicCommitteeMembers = res[0];
        this.linesOfResearch = res[1];
        this.modifyEvaluationsAuth = res[2];
        this.coordinator = res[3];
        this.faculties = res[4];
        this.studentRepresentatives = res[5];
        this.loading.stop();
        this.ready = true;
      })
  }

  addAC() {
    if (!this.addACUser) return;
    this.ps.addACUser(this.program._id, this.addACUser._id).subscribe(() => {
      this.academicCommitteeMembers.push(this.addACUser);
      this.addACUser = null;
      this.addACUserSelector.resetFind();
      this.addACUserSelector.email = '';
      this.alerts.saveSuccess();
    })
  }

  removeAC(usr: IUser) {
    this.ps.removeACUser(this.program._id, usr._id).subscribe(() => {
      this.academicCommitteeMembers = this.academicCommitteeMembers.filter(u => u._id !== usr._id);
      this.alerts.saveSuccess();
    })
  }

  addSR() {
    if (!this.addSRUser) return;
    this.ps.addSRUser(this.program._id, this.addSRUser._id).subscribe(() => {
      this.studentRepresentatives.push(this.addSRUser);
      this.addSRUser = null;
      this.addSRUserSelector.resetFind();
      this.addSRUserSelector.email = '';
      this.alerts.saveSuccess();
    })

  }

  removeSR(usr: IUser) {
    this.ps.removeSRUser(this.program._id, usr._id).subscribe(() => {
      this.studentRepresentatives = this.studentRepresentatives.filter(u => u._id !== usr._id);
      this.alerts.saveSuccess();
    })
  }

  addAuth() {
    if (!this.addAuthUser) return;
    this.ps.authModifyEvaluations(this.program._id, this.addAuthUser._id).subscribe(() => {
      this.modifyEvaluationsAuth.push(this.addAuthUser);
      this.addAuthUser = null;
      this.addAuthUserSelector.resetFind();
      this.addAuthUserSelector.email = '';
      this.alerts.saveSuccess();
    })
  }

  removeAuth(usr: IUser) {
    this.ps.removeAuthModifyEvaluations(this.program._id, usr._id).subscribe(() => {
      this.modifyEvaluationsAuth = this.modifyEvaluationsAuth.filter(u => u._id !== usr._id);
      this.alerts.saveSuccess();
    })
  }

  addLineOfResearch() {
    if (!this.newLineResearch || this.newLineResearch.length <= 0) {
      return;
    }

    this.ps.addLineOfResearch(this.program._id, this.newLineResearch).subscribe((res) => {
      this.linesOfResearch.push({
        _id: res.lineOfResearchId,
        name: this.newLineResearch,
        programId: this.program._id
      })
      this.alerts.saveSuccess();
      this.newLineResearch = '';
    })
  }

  removeLineOfResearch(lr: ILineOfResearch) {
    this.linesOfResearchToSwitch = this.linesOfResearch.filter(l => l._id.toString() !== lr._id.toString())
    this.lineToRemove = lr;
    this.newLineToSwithId = null;
    this.cds.confirm({
      title: this.ts.instant('remove-line-of-research'),
      message: this.ts.instant('remove-line-of-research-msg', { lr: lr.name }),
      template: this.removeLineConfirmationTemplate
    }, () => {
      this.ps.removeLineOfResearch(this.program._id, lr._id, this.newLineToSwithId).subscribe((res) => {
        this.linesOfResearch = this.linesOfResearch.filter(l => l._id.toString() !== lr._id.toString());
        this.alerts.saveSuccess();
      })
    })
  }

  openEditLineModal(line: ILineOfResearch) {
    this.lineToEdit = line;
    this.lineNewName = line.name;
    this.openEditLine = true;
  }

  editLineOfResearch() {
    if (this.editLineForm.invalid) return;

    this.ps.editLineOfResearch(this.program._id, this.lineToEdit._id, this.lineNewName).subscribe(() => {
      this.linesOfResearch.find(l => l._id.toString() === this.lineToEdit._id).name = this.lineNewName;
      this.alerts.saveSuccess();
      this.openEditLine = false;
    })
  }

  openEditModal() {
    this.programEdit = _.cloneDeep(this.program);
    this.editOpen = true;
  }

  saveProgram() {
    this.ps.edit(this.program._id, this.programEdit.name, this.programEdit.facultyId, this.programEdit.coordinatorId).subscribe(() => {
      this.program = this.programEdit;
      this.program.coordinator = this.academicCommitteeMembers.find(ec => ec._id.toString() === this.programEdit.coordinatorId.toString());
      this.coordinator = this.program.coordinator;
      this.program.faculty = this.faculties.find(f => f._id.toString() === this.programEdit.facultyId.toString())
      this.alerts.saveSuccess();
      this.editOpen = false;
    })
  }


}
