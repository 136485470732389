<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body"
        *ngIf="sr.student">

        <ng-container *ngIf="!requested">

          <h5 class="card-title">
            {{ 'process.stay.request.name' | translate }}
          </h5>

          <p [innerHTML]="'process.stay.request.desc' | translate">
          </p>

          <stay-request-form [disabled]="disabled"
            [sending]="sending"
            [data]="data"
            (onSend)="send($event)"></stay-request-form>
        </ng-container>
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'request-sended' | translate }}
          </h5>

          <div *ngIf="doc"
            class="mt-4 w-100 text-left">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>