import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TKCDS } from '../../../../interfaces/ICDSProcess';
import { TKDedication } from '../../../../interfaces/IDedicationProcess';
import { TKDirection } from '../../../../interfaces/IDirectionProcess';
import { TKDischarge } from '../../../../interfaces/IDischargeProcess';
import { IEvaluationCommittee } from '../../../../interfaces/IEvaluationCommittee';
import { TKEvaluation } from '../../../../interfaces/IEvaluationProcess';
import { TKExtension } from '../../../../interfaces/IExtensionProcess';
import { TKLeave } from '../../../../interfaces/ILeaveProcess';
import { IStudent } from '../../../../interfaces/IStudent';
import { ITask, Processes } from '../../../../interfaces/ITask';
import { EMAIL_REGEX } from '../../../../interfaces/IUser';
import { TKValidation } from '../../../../interfaces/IValidationProcess';
import { DADUtils } from '../../../../utils/DADUtils';
import { TKPermanentLeave } from '../../../../interfaces/IPermanentLeaveProcess';
import { TKRenewal } from '../../../../interfaces/IRenewalProcess';
import { TKIndustrial } from '../../../../interfaces/IIndustrialProcess';

@Injectable()
export class HelperService {

  EMAIL_REGEX;

  constructor(@Inject(DOCUMENT) private document: Document, private translate: TranslateService) {
    this.EMAIL_REGEX = EMAIL_REGEX;
  }

  trackById(index, item) {
    return item._id
  }

  name(task: ITask) {
    return `process.${task.processKey}.tasks.${task.taskKey}.name`;
  }

  descKey(task: ITask) {
    return `process.${task.processKey}.tasks.${task.taskKey}.desc`;
  }

  descParams(task: ITask) {
    switch (task.processKey) {
      case Processes.validation:
        return this.validationProcessDescParams(task);
      case Processes.cds:
        return this.cdsProcessDescParams(task);
      case Processes.dedication:
        return this.dedicationProcessDescParams(task);
      case Processes.direction:
        return this.directionProcessDescParams(task);
      case Processes.industrial:
        return this.industrialProcessDescParams(task);
      case Processes.extension:
        return this.extensionProcessDescParams(task);
      case Processes.leave:
        return this.leaveProcessDescParams(task);
      case Processes.permanentLeave:
        return this.permanentLeaveProcessDescParams(task);
      case Processes.discharge:
        return this.dischargeProcessDescParams(task);
      case Processes.renewal:
        return this.renewalProcessDescParams(task);
      case Processes.evaluation:
        return this.evaluationProcessDescParams(task);
      case Processes.thesis:
        return this.thesisProcessDescParams(task);
      case Processes.professor:
        return this.professorProcessDescParams(task);
      case Processes.stay:
        return this.stayProcessDescParams(task);

      default:
        return {};
    }

  }

  processName(processKey: Processes) {
    return `process.${processKey}.name`;
  }

  roleName(role: string) {
    let split = role.split('-');
    return split[0];
  }

  ecCode(evaluationCommittee: IEvaluationCommittee) {
    if (!evaluationCommittee) return "";
    return `CE-${evaluationCommittee.year}-${evaluationCommittee.program.code}-${evaluationCommittee.number}`
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  scrollToInvalidFeedback() {
    const invalidFeedback = this.document.querySelector('.invalid-feedback');
    if (invalidFeedback) {
      invalidFeedback.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getStudentFromTask(task: ITask): IStudent {
    switch (task.processKey) {
      case Processes.validation:
        return task.validationProcess.student;
      case Processes.cds:
        return task.cdsProcess.student;
      case Processes.dedication:
        return task.dedicationProcess.student;
      case Processes.direction:
        return task.directionProcess.student;
      case Processes.industrial:
        return task.industrialProcess.student;
      case Processes.extension:
        return task.extensionProcess.student;
      case Processes.leave:
        return task.leaveProcess.student;
      case Processes.permanentLeave:
        return task.permanentLeaveProcess.student;
      case Processes.discharge:
        return task.dischargeProcess.student;
      case Processes.renewal:
        return task.renewalProcess.student;
      case Processes.evaluation:
        return task.evaluationProcess.student;
      case Processes.thesis:
        return task.thesisProcess.student;
      case Processes.stay:
        return task.stayProcess.student;

      default:
        return null;
    }
  }

  private validationProcessDescParams(task: ITask) {
    if (!task.validationProcess) return {};
    switch (task.taskKey) {
      case TKValidation.validation:
        return {
          activity: task.validationProcess.activity.title,
          student: DADUtils.fullname(task.validationProcess.student)
        }
      case TKValidation.review:
        return {
          activity: task.validationProcess.activity.title,
          validationMsg: task.validationProcess.validationMsg
        }
      default:
        return {};
    }
  }

  private cdsProcessDescParams(task: ITask) {
    if (!task.cdsProcess) return {};
    switch (task.taskKey) {
      case TKCDS.academicCommitteeValidation:
      case TKCDS.serviceValidation:
      case TKCDS.directionTeamUserCreation:
      case TKCDS.postgraduateCommitteeValidation:
      case TKCDS.studentSign:
      case TKCDS.directionTeamSign:
      case TKCDS.doctoralSchoolPrincipalSign:
      case TKCDS.postgraduateCommitteePresidentSign:
        return {
          student: DADUtils.fullname(task.cdsProcess.student),
          program: task.cdsProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private dedicationProcessDescParams(task: ITask) {
    if (!task.dedicationProcess) return {};
    switch (task.taskKey) {
      case TKDedication.directionTeamValidation:
      case TKDedication.academicCommitteeValidation:
      case TKDedication.postgraduateCommitteeValidation:
        return {
          student: DADUtils.fullname(task.dedicationProcess.student),
          program: task.dedicationProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private directionProcessDescParams(task: ITask) {
    if (!task.directionProcess) return {};
    switch (task.taskKey) {
      case TKDirection.academicCommitteeValidation:
      case TKDirection.serviceValidation:
      case TKDirection.directionTeamUserCreation:
      case TKDirection.postgraduateCommitteeValidation:
      case TKDirection.studentSign:
      case TKDirection.directionTeamSign:
      case TKDirection.doctoralSchoolPrincipalSign:
      case TKDirection.postgraduateCommitteePresidentSign:
        return {
          student: DADUtils.fullname(task.directionProcess.student),
          program: task.directionProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private industrialProcessDescParams(task: ITask) {
    if (!task.industrialProcess) return {};
    switch (task.taskKey) {
      case TKIndustrial.academicCommitteeSign:
      case TKIndustrial.postgraduateCommitteePresidentSign:
      case TKIndustrial.directionTeamSign:
      case TKIndustrial.studentSign:
      case TKIndustrial.doctoralSchoolPrincipalSign:
        return {
          student: DADUtils.fullname(task.industrialProcess.student),
          program: task.industrialProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private extensionProcessDescParams(task: ITask) {
    if (!task.extensionProcess) return {};
    switch (task.taskKey) {
      case TKExtension.academicCommitteeValidation:
        return {
          student: DADUtils.fullname(task.extensionProcess.student)
        }
      default:
        return {};
    }
  }

  private leaveProcessDescParams(task: ITask) {
    if (!task.leaveProcess) return {};
    switch (task.taskKey) {
      case TKLeave.academicCommitteeValidation:
      case TKLeave.serviceValidation:
        return {
          student: DADUtils.fullname(task.leaveProcess.student),
          program: task.leaveProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private permanentLeaveProcessDescParams(task: ITask) {
    if (!task.permanentLeaveProcess) return {};
    switch (task.taskKey) {
      case TKPermanentLeave.coordinatorReport:
        return {
          student: DADUtils.fullname(task.permanentLeaveProcess.student),
          program: task.permanentLeaveProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private dischargeProcessDescParams(task: ITask) {
    if (!task.dischargeProcess) return {};
    switch (task.taskKey) {
      case TKDischarge.academicCommitteeValidation:
      case TKDischarge.serviceValidation:
        return {
          student: DADUtils.fullname(task.dischargeProcess.student),
          program: task.dischargeProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private renewalProcessDescParams(task: ITask) {
    if (!task.renewalProcess) return {};
    switch (task.taskKey) {
      case TKRenewal.serviceValidation:
        return {
          student: DADUtils.fullname(task.renewalProcess.student),
          program: task.renewalProcess.student.program.name
        }
      default:
        return {};
    }
  }

  private evaluationProcessDescParams(task: ITask) {
    if (!task.evaluationProcess) return {};
    switch (task.taskKey) {
      case TKEvaluation.directionTeamReport:
      case TKEvaluation.evaluationCommitteeConfirmation:
      case TKEvaluation.confirmationBySecretary:
      case TKEvaluation.evaluationCommitteeEvaluation:
      default:
        return {
          student: DADUtils.fullname(task.evaluationProcess.student),
          program: task.evaluationProcess.student.program.name
        }
    }
  }

  professorProcessDescParams(task: ITask) {
    if (!task.professorProcess) return {};
    return {
      program: task.professorProcess.program.name,
      lineOfResearch: task.professorProcess.lineOfResearch.name,
      professorName: task.professorProcess.lastName + ', ' + task.professorProcess.firstName
    }
  }

  stayProcessDescParams(task: ITask) {
    if (!task.stayProcess) return {};
    return {
      student: DADUtils.fullname(task.stayProcess.student),
    }
  }

  private thesisProcessDescParams(task: ITask) {
    if (!task.thesisProcess) return {};
    return {
      student: DADUtils.fullname(task.thesisProcess.student),
      program: task.thesisProcess.student.program.name
    }
  }

  disabledRequestReasons(reasons: string[]) {
    let reasonsTr = '';
    reasons.forEach(r => {
      reasonsTr += `<li>${this.translate.instant(r)}</li>`
    })
    let title = this.translate.instant('cant-request-process-for-the-following-reasons');
    if (reasons.length <= 1) {
      title = this.translate.instant('cant-request-process-for-the-following-reason')
    }

    return `
    <div class="text-left">
      ${title}: <ul class="mt-2 mb-0">${reasonsTr}</ul>
    </div>`;
  }

}